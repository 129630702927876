//* Assets */
import Copy from "@Assets/icons/copy.png";

//* Utils */
import { copyToClipboard } from "@Utils/common";
import { useRef } from "react";

interface ICopyToClipboard {
  content: string | number;
  isUnMasked?: boolean;
  getFieldMaskedInfo?: () => Promise<void>;
}

export default function CopyToClipboard({
  content,
  isUnMasked,
  getFieldMaskedInfo,
}: ICopyToClipboard) {
  const copyRef = useRef<HTMLImageElement>(null);
  const handleCopyToClipboard = async () => {
    if (isUnMasked) {
      copyToClipboard(content);
    } else if (getFieldMaskedInfo) {
      await getFieldMaskedInfo();
      copyToClipboard(copyRef?.current?.dataset?.content || "");
    }
  };

  return (
    <img
      ref={copyRef}
      src={Copy}
      alt="copy"
      data-content={isUnMasked ? content : ""}
      className="w-3 h-3 ml-0.5 cursor-pointer"
      onClick={handleCopyToClipboard}
    />
  );
}
