//* Type Imports */
import { ILeadAssigneeUsers, ILeadTasks, INoteDetails } from "@Types/common";

export const transformLeadCreatedBy = (
  notes: INoteDetails[] | ILeadTasks[],
) => {
  return notes.map((note) => ({
    ...note,
    createdBy: {
      ...note.createdBy,
      name: note.createdBySystem ? "System" : note.createdBy?.name,
    },
  }));
};

export const updateFieldFromOptions = (
  options: any[],
  fieldName: any,
  response: any[],
  valueKey: string = "value",
) => {
  const value = response?.[fieldName]?.toString()?.toLowerCase();
  const matchedOption = options.find(
    (option) => option?.[valueKey].toLowerCase() === value,
  );
  if (matchedOption) {
    response[fieldName] = matchedOption;
  }
};

export const transformLeadAssignees = (
  assigneeList: ILeadAssigneeUsers[],
  authUserId: number | null,
) => {
  let firstEntry;
  const otherAssignees = [];

  for (const user of assigneeList) {
    const entry = {
      id: user.id,
      loanPartner: user.loanPartner,
      value: user.name,
      type: user.type,
      label: user.type ? `${user.name} - ${user.type}` : user.name,
    };

    if (user.id === authUserId) {
      entry.label = "Me";
      firstEntry = entry;
    } else {
      otherAssignees.push(entry);
    }
  }

  return firstEntry ? [firstEntry, ...otherAssignees] : otherAssignees;
};
