export const LEAD_DETAILS_MAPPER = {
  alt_phone: "altPhone",
  country: "country",
  university: "university",
  permanent_city: "permanentCity",
  loan_amount: "loanAmount",
  verified_email: "verifiedEmail",
  verified_phone: "verifiedPhone",
  created_at: "createdAt",
  updated_at: "updatedAt",
  sanction_agent: "sanctionAgent",
  am_agent: "amAgent",
  rg_agent: "rgAgent",
  lost_reason: "lostReason",
  status_list: "statusList",
  lender_data: "lenderData",
};

export const NOTE_MAPPER = {
  created_at: "createdAt",
  created_by: "createdBy",
  created_by_system: "createdBySystem",
  sender_type: "senderType",
  sender_image: "senderImage",
};

export const LEAD_ASSIGNEE_MAPPER = {
  loan_partner: "loanPartner",
};

export const MARKETING_DETAILS_MAPPER = {
  utm_source: "utmSource",
  utm_campaign: "utmCampaign",
  utm_medium: "utmMedium",
  utm_term: "utmTerm",
  utm_content: "utmContent",
  client_ip: "clientIP",
};

export const BASIC_DETAILS_MAPPER = {
  first_name: "firstName",
  last_name: "lastName",
  phone: "MobileNo",
  alt_phone: "SecondaryMobileNo",
  country_code: "countryCode",
  alt_country_code: "alternateCountryCode",
};

export const ACADEMIC_DETAILS_MAPPER = {
  course: "areaOfStudy",
  admission_status: "admissionStatus",
  overall_score: "overallScore",
  no_of_backlogs: "noOfBacklogs",
  university_join_date: "intake",
};

export const LOAN_DETAILS_MAPPER = {
  loan_amount: "loanAmount",
  loan_type: "loanType",
  existing_emi: "existingEMI",
  already_applied_to: "alreadyAppliedTo",
};

export const CO_APPLICANT_MAPPER = {
  first_name: "firstName",
  last_name: "lastName",
  country_code: "countryCode",
  existing_emi: "ongoingEmis",
  employment_type: "employmentType",
};

export const TASK_MAPPER = {
  lead_link: "leadLink",
  lead_status: "leadStatus",
  application_status: "applicationStatus",
  task_category: "taskCategory",
  remind_at: "remindAt",
  created_by: "createdBy",
  created_at: "createdAt",
  updated_at: "updatedAt",
  is_complete: "isCompleted",
  created_by_system: "createdBySystem",
};

export const LOAN_APPLICATION_MAPPER = {
  uc_status: "ucStatus",
  sanction_details: "sanctionDetails",
  application_id: "applicationId",
  loan_amount: "loanAmount",
  sanctioned_at: "sanctionedAt",
  pf_amount: "pfAmount",
  pf_details: "pfDetails",
  paid_at: "paidAt",
  first_tranche_amount: "firstTrancheAmount",
  no_of_tranches: "noOfTranches",
  tranches_comments: "tranchesComments",
  cancellation_details: "cancellationDetails",
  created_at: "createdAt",
  lender_details: "lenderDetails",
  is_diy: "isDiy",
  lender_name: "lenderName",
  partner_name: "partnerName",
  disbursal_details: "disbursalDetails",
  disbursed_amount: "disbursedAmount",
  disbursal_date: "disbursalDate",
  disbursed_comment: "disbursedComment",
  cancelled_by: "cancelledBy",
};

export const DOCUMENTS_MAPPER = {
  coapplicant_count: "coapplicantCount",
  mandatory_documents: "mandatoryDocuments",
  total_documents: "totalDocuments",
  meta_data: "metaData",
  document_type: "documentType",
};
