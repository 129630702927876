//* Packages */
import { useParams } from "react-router-dom";

//* Components */
import Tag from "@Core/Tag";
import Select from "@Core/Select";

//* Services */
import { getTags } from "@Actions/app";
import { updateTags } from "@Actions/lead";

//* Utils */
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";

//* Types & Enums */
import { ITag } from "@Types/common";
import { TAG_ACTION } from "@Enums/common";

//* Assets */
import Loading from "@Assets/images/loader.gif";

interface ITags {
  leadTags: ITag[];
}

export default function Tags({ leadTags }: ITags) {
  const { leadId } = useParams();
  const dispatch = useAppDispatch();

  const canUpdateTag = useAppSelector(
    (state) => state.user.access?.detail.leadDetails,
  );
  const allTags = useAppSelector((state) => state.app.tags);
  const tagsLoading = useAppSelector((state) => state.lead.tagsLoading);

  const handleRemoveTag = (tag: ITag) => {
    if (!canUpdateTag?.delete) return;
    dispatch(
      updateTags({
        leadId: Number(leadId),
        action: TAG_ACTION.REMOVE,
        tag,
      }),
    );
  };

  const addTag = (tag: ITag) => {
    dispatch(
      updateTags({
        leadId: Number(leadId),
        action: TAG_ACTION.ADD,
        tag,
      }),
    );
  };

  return (
    <div className="flex items-center gap-2 flex-wrap">
      <div className="mb-2 text-xs text-placeholder-gray">Tags</div>

      {leadTags.map((tag, index) => (
        <Tag
          key={index}
          tag={tag}
          removeCallback={handleRemoveTag}
          enableRemove={canUpdateTag?.delete}
        />
      ))}

      {canUpdateTag?.change && (
        <>
          <div className="w-[170px]">
            <Select
              placeholder="Add tag"
              options={allTags}
              labelKey="name"
              valueKey="code"
              size="xxs"
              setValue={(tag: ITag) => addTag(tag)}
              disabled={tagsLoading}
              fetchOptions={() => dispatch(getTags())}
            />
          </div>
          {tagsLoading ? (
            <img src={Loading} alt="loader" className="w-5 h-5" />
          ) : null}
        </>
      )}
    </div>
  );
}
