//* Packages */
import { createAsyncThunk } from "@reduxjs/toolkit";

//* Utils */
import API from "@Axios/main";
import { ALL_COUNTRIES, ALL_PARTNERS, ALL_TAGS } from "@Constants/urls";
import { toastService } from "@Utils/toast";
import { TAGS_MAPPER } from "@Mappers/app";

//* Redux */
import {
  setAppLoading,
  setCountries,
  setTags,
  setPartners,
} from "@Slice/AppSlice";

const _API = new API("v1");
const _API2 = new API();

export const getTags = createAsyncThunk("app/tags", async (_, { dispatch }) => {
  dispatch(setAppLoading(true));
  try {
    const res = await _API.get(ALL_TAGS, TAGS_MAPPER);
    dispatch(setTags(res));
  } catch (e: any) {
    toastService.notify(
      "error",
      e.response?.data?.message || "Something went wrong. Please try again!",
    );
  } finally {
    dispatch(setAppLoading(false));
  }
});

export const getCountries = createAsyncThunk(
  "app/countries",
  async (_, { dispatch }) => {
    dispatch(setAppLoading(true));
    try {
      const res = await _API2.get(ALL_COUNTRIES);
      dispatch(setCountries(res));
    } catch (e: any) {
      toastService.notify(
        "error",
        e.response?.data?.message || "Something went wrong. Please try again!",
      );
    } finally {
      dispatch(setAppLoading(false));
    }
  },
);

export const getPartners = createAsyncThunk(
  "app/partners",
  async (_, { dispatch }) => {
    dispatch(setAppLoading(true));
    try {
      const res = await _API2.get(ALL_PARTNERS);

      dispatch(setPartners(res));
    } catch (e: any) {
      toastService.notify(
        "error",
        e.response?.data?.message || "Something went wrong. Please try again!",
      );
    } finally {
      dispatch(setAppLoading(false));
    }
  },
);
