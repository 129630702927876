export enum StatusKeyEnum {
  CREATED = "CREATED",
  CONTACTED = "CONTACTED",
  OPPORTUNITY = "OPPORTUNITY",
  PROCESSING = "PROCESSING",
  IMPORTANT = "IMPORTANT",
  DOCS_PENDING = "DOCS_PENDING",
  LOGGED_IN = "LOGGED_IN",
  SANCTIONED = "SANCTIONED",
  FOLLOW_UPS = "FOLLOW_UPS",
  NEW_LEADS = "NEW_LEADS",
  PF_CONFIRMED = "PF_CONFIRMED",
  DISBURSED = "DISBURSED",
  LOST = "LOST",
  REJECTED = "REJECTED",
  CROSS_SALE_IN_PROCESS = "CROSS_SALE_IN_PROCESS",
  READY_TO_LOGIN = "READY_TO_LOGIN",
  LEAD_FORWARDED = "LEAD_FORWARDED",
  WITHDRAWN = "WITHDRAWN",
  YET_TO_CONNECT = "YET_TO_CONNECT",
  UNDER_DISCUSSION = "UNDER_DISCUSSION",
  FUTURE_DATE = "FUTURE_DATE",
  NOT_RESPONDING = "NOT_RESPONDING",
  AWAITING_DOCS = "AWAITING_DOCS",
  DISBURSEMENT = "DISBURSEMENT",
  NO_PRODUCT_OR_NOT_ELIGIBLE = "NO_PRODUCT_OR_NOT_ELIGIBLE",
  PAID = "PAID",
  NOT_PAID = "NOT_PAID",
  PARTIALLY_PAID = "PARTIALLY_PAID",
  PRE_QUALIFIED = "PRE_QUALIFIED",
  LQT_LOST = "LQT_LOST",
}

export enum TAG_ACTION {
  REMOVE = "remove",
  ADD = "add",
}

export enum FORM_STEP {
  EMAIL = "email",
  OTP = "otp",
}

export enum APPLICATION_CANCELLATION {
  REJECTED = "REJECTED",
  WITHDRAWN = "WITHDRAWN",
}

export enum KINDS {
  CALL_INCOMING = "CALL_INCOMING",
  CALL_OUTGOING = "CALL_OUTGOING",
  EMAIL_INBOUND = "EMAIL_INBOUND",
  EMAIL_OUTBOUND = "EMAIL_OUTBOUND",
  WHATSAPP_INBOUND = "WHATSAPP_INBOUND",
  WHATSAPP_OUTBOUND = "WHATSAPP_OUTBOUND",
  ASSIGNMENT = "ASSIGNMENT",
  REASSIGNMENT = "REASSIGNMENT",
  FORWARD_TO_LENDER = "FORWARD_TO_LENDER",
  NOTE = "NOTE",
  STATUS = "STATUS",
  TASK = "TASK",
  TAG = "TAG",
  APPLICATION = "APPLICATION",
}

export enum LEAD_TEAM_LIST {
  SALES_AGENT = "agent",
  SANCTION_AGENT = "sanction_agent",
  RG_AGENT = "rg_agent",
  ASSISTANT_MANAGER = "am_agent",
}

export enum CELL_TYPE {
  TEXT = "text",
  BADGE = "badge",
  LEAD = "lead",
  LINK = "link",
  EDIT = "edit",
}
export enum LENDER_TEAM_LIST {
  LENDER_EXECUTIVE = "executive",
  LENDER_MANAGER = "manager",
  LENDER_REGIONAL_HEAD = "regional_head",
}
