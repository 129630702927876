//* Packages Imports */
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

//* Components Imports */
import SkeletonCard from "@Core/SkeletonLoader/Card";
import FormGenerator, { InputFieldsType } from "@Core/FormGenerator";

//* Service Imports */
import { lead } from "@Slice/LeadSlice";
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";
import { getLoanDetails, setLeadLoanDetails } from "@Actions/lead";

//* Data Imports */
import { BOOLEAN_LIST } from "@Constants/mock";
import { LOAN_TYPE } from "@Constants/leadDetailsData";

//* Assets Imports */
import CoinRupeeIcon from "@Assets/icons/CoinRupee.png";

//* Types Imports */
import { ILoanDetails } from "@Types/leads";

const LoanDetails = () => {
  const dispatch = useAppDispatch();
  const { isLoading, saveLoader, loanDetails } =
    useAppSelector(lead).leadDetails.loanDetailsObj;
  const { partners } = useAppSelector((state) => state.app);
  const { access } = useAppSelector((state) => state.user);

  const isDisabled = !access?.detail.loanDetails.change;
  const { leadId } = useParams();

  const formInputData: Array<InputFieldsType> = [
    {
      type: "text",
      name: "loanAmount",
      label: "Loan Amount",
      required: true,
      disabled: isDisabled,
    },
    {
      type: "select",
      name: "loanType",
      label: "Loan Type",
      options: LOAN_TYPE,
      disabled: isDisabled,
    },
    {
      type: "select",
      name: "employed",
      label: "Employed",
      required: true,
      options: BOOLEAN_LIST,
      disabled: isDisabled,
    },
    {
      type: "select",
      name: "salaried",
      label: "Salaried",
      options: BOOLEAN_LIST,
      disabled: isDisabled,
    },
    {
      type: "number",
      name: "existingEMI",
      label: "Existing EMI",
      required: true,
      disabled: isDisabled,
    },
    {
      type: "select",
      name: "alreadyAppliedTo",
      label: "Already applied to",
      variant: "multiSelect",
      options: partners,
      labelKey: "name",
      valueKey: "id",
      disabled: isDisabled,
    },
  ];

  const inputFieldsData = useMemo(() => {
    return formInputData;
  }, [partners, loanDetails]);

  const handleFormSubmit = (formData: ILoanDetails) => {
    if (isNaN(Number(leadId))) return;

    const updatedFormData = { ...formData };
    if (loanDetails?.id && updatedFormData?.id) {
      updatedFormData.id = loanDetails.id;
    }

    dispatch(
      setLeadLoanDetails({
        leadId: Number(leadId),
        payload: updatedFormData,
      }),
    );
  };

  useEffect(() => {
    if (leadId) dispatch(getLoanDetails(Number(leadId)));
  }, [partners]);

  return (
    <div>
      <h3 className="flex gap-2 mb-7">
        <img src={CoinRupeeIcon} alt="Coin Rupee" />
        Loan Details
      </h3>
      {isLoading ? (
        <SkeletonCard contentLines={9} gridCol={2} />
      ) : (
        <FormGenerator
          inputFieldsData={inputFieldsData}
          onSubmit={(formData) => handleFormSubmit(formData)}
          gridCol={2}
          defaultValues={loanDetails || {}}
          hideSubmitButton={isDisabled}
          disableSubmitBtn={saveLoader}
        />
      )}
    </div>
  );
};

export default LoanDetails;
