//* Packages */
import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

//* Slices */
import {
  setAllLeadsLoading,
  setAllLeads,
  setStatusList,
  setStatusCountData,
  setStatusLoading,
  setMyTasksLoading,
  setMyTasks,
} from "@Slice/DashboardSlice";

//* Utils */
import API from "@Axios/main";
import {
  GET_MY_TASKS,
  LEAD_DETAILS,
  LEAD_STATUS,
  LEAD_STATUS_COUNT,
} from "@Constants/urls";
import { toastService } from "@Utils/toast";
import { ALL_LEADS_MAPPER, MY_TASKS_MAPPER } from "@Mappers/dashboard";
import { transformLeadCreatedBy } from "@Utils/lead";

//* Types & Enums */
import { ILeadsListingItem } from "@Types/dashboard";
import {
  ILeadTasks,
  IPageCountPaginatedResponse,
  StatusKey,
} from "@Types/common";

type IFiltersPayload = Record<string, string | number>;

const _API = new API();

export const getAllLeads = createAsyncThunk(
  "dashboard/getAllLeads",
  async (filters: IFiltersPayload, { dispatch }) => {
    dispatch(setAllLeadsLoading(true));
    try {
      const response: IPageCountPaginatedResponse<ILeadsListingItem[]> =
        await _API.get(LEAD_DETAILS, ALL_LEADS_MAPPER, {
          params: filters || {},
        });

      dispatch(
        setAllLeads({
          pages: response.totalPages,
          count: response.totalItems,
          list: response.results || [],
        }),
      );
    } catch (err: unknown) {
      console.error("Error: ", err);
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify(
        "error",
        errorMessage || "An error occured while fetching leads!",
      );
    } finally {
      dispatch(setAllLeadsLoading(false));
    }
  },
);

interface IStatusResponse {
  data: StatusKey[];
}

export const getAllStatus = createAsyncThunk(
  "dashboard/getAllStatus",
  async (_, { dispatch }) => {
    try {
      const response: IStatusResponse = await _API.get(LEAD_STATUS);
      if (Array.isArray(response.data)) {
        dispatch(setStatusList(response.data));
      }
    } catch (err: unknown) {
      console.error("Error: ", err);
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify(
        "error",
        errorMessage || "An error occured while fetching status!",
      );
    }
  },
);

export const getStatusCount = createAsyncThunk(
  "dashboard/getStatusCount",
  async (_, { dispatch }) => {
    try {
      dispatch(setStatusLoading(true));
      const response: IStatusResponse = await _API.get(LEAD_STATUS_COUNT);
      if (response) {
        dispatch(setStatusCountData(response));
      }
    } catch (err: unknown) {
      console.error("Error: ", err);
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify(
        "error",
        errorMessage || "An error occured while fetching count!",
      );
    } finally {
      dispatch(setStatusLoading(false));
    }
  },
);

export const getMyTasks = createAsyncThunk(
  "dashboard/getMyTasks",
  async (filters: IFiltersPayload, { dispatch }) => {
    dispatch(setMyTasksLoading(true));
    try {
      const response: IPageCountPaginatedResponse<ILeadTasks[]> =
        await _API.get(GET_MY_TASKS, MY_TASKS_MAPPER, {
          params: filters || {},
        });
      if (response.results) {
        const allTasks = transformLeadCreatedBy(response.results);
        dispatch(
          setMyTasks({
            pages: response.totalPages,
            count: response.totalItems,
            list: allTasks,
          }),
        );
      }
    } catch (err: unknown) {
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      console.error("Error: ", errorMessage);
      toastService.notify(
        "error",
        errorMessage || "An error occured while fetching tasks!",
      );
    } finally {
      dispatch(setMyTasksLoading(false));
    }
  },
);
