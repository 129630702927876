//* Packages Imports */
import { useState } from "react";
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";

//* Compponents Imports */
import Input from "@Core/Input";
import Button from "@Core/Button";
import Select from "@Core/Select";
import PhoneInput from "@Core/PhoneInput";
import DynamicFormDatePicker from "@Core/Datepicker/DynamicFormDatePicker";

//* Service Imports */
import { setCoApplicantDetail } from "@Actions/lead";
import { getStructuredDate } from "@Utils/timeUtils";
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";

//* Data Imports */
import { CITIES, STATES } from "@Constants/mock";
import { EMPLOYEMENT_TYPE } from "@Constants/leadDetailsData";

//* Assets Imports */
import Loading from "@Assets/images/loader.gif";
import CoApplicantIdIcon from "@Assets/icons/CoApplicantId.png";

//* Types Imports */
import { ICoApplicantDetails } from "@Types/leads";
import { IDatePickerSelection } from "@Types/common";

//* Styles Imports */
import Styles from "@Components/pages/leads/lead-details/StudentDetailsSection/StudentDetailsSection.module.scss";

const CoApplicantForm = ({
  leadId,
  coApplicant,
  formIndex,
  removeCoApplicant,
}: {
  leadId: number;
  coApplicant: ICoApplicantDetails;
  formIndex: number;
  removeCoApplicant: (coApplicant: ICoApplicantDetails) => void;
}) => {
  const dispatch = useAppDispatch();
  const { access } = useAppSelector((state) => state.user);

  const isDisabled = !access?.detail.coapplicantDetails.change;

  const [isLoading, setIsLoading] = useState(false);

  const getDefaultValues = () => {
    return {
      ...coApplicant,
      dob: {
        selectedDate: coApplicant?.dob as string,
        startDate: coApplicant?.dob as string,
        endDate: null,
      },
    };
  };

  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<ICoApplicantDetails>({
    defaultValues: getDefaultValues(),
  });

  const onSubmit = async (data: ICoApplicantDetails) => {
    setIsLoading(true);

    const updatedPayload = { ...data };

    if (typeof data.dob !== "string") {
      updatedPayload.dob = getStructuredDate(data.dob.selectedDate);
    }

    await dispatch(
      setCoApplicantDetail({
        leadId: leadId,
        payload: updatedPayload,
      }),
    );
    setIsLoading(false);
    reset(getDefaultValues());
  };

  return (
    <div>
      <h3 className="flex gap-2 mb-7">
        <img src={CoApplicantIdIcon} alt="Coin Rupee" /> Co-Applicant (
        {formIndex + 1}) Details
      </h3>
      <form key={coApplicant?.id} onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-x-10 gap-y-4">
          <Controller
            name="firstName"
            control={control}
            rules={{ required: "First Name is required" }}
            render={({ field: { ref, ...field } }) => (
              <div className={errors?.firstName?.message && Styles.errorBorder}>
                <Input
                  {...field}
                  label="First Name"
                  labelPosition="left"
                  inputRef={ref}
                  mandatory
                  disabled={isDisabled}
                />
              </div>
            )}
          />
          <Controller
            name="lastName"
            control={control}
            rules={{ required: "Last Name is required" }}
            render={({ field: { ref, ...field } }) => (
              <div className={errors?.lastName?.message && Styles.errorBorder}>
                <Input
                  {...field}
                  label="Last Name"
                  labelPosition="left"
                  inputRef={ref}
                  mandatory
                  disabled={isDisabled}
                />
              </div>
            )}
          />
          <Controller
            name="relation"
            control={control}
            rules={{ required: "Relation is required" }}
            render={({ field: { ref, ...field } }) => (
              <div className={errors?.relation?.message && Styles.errorBorder}>
                <Input
                  {...field}
                  label="Relation"
                  labelPosition="left"
                  inputRef={ref}
                  mandatory
                  disabled={isDisabled}
                />
              </div>
            )}
          />
          <Controller
            name="phone"
            control={control}
            rules={{ required: "Phone number is required" }}
            render={({ field: { ref, ...field } }) => (
              <div
                className={errors?.phone?.message && Styles.errorBorder}
                ref={ref}
              >
                <PhoneInput
                  {...field}
                  label="Phone Number"
                  inputDefaultValue={field.value}
                  countryCodeDefaultValue={coApplicant?.countryCode}
                  onCountryCodeSelect={(option: any) =>
                    setValue("countryCode", option, { shouldDirty: true })
                  }
                  onInputChange={(e) =>
                    setValue("phone", e.target.value, { shouldDirty: true })
                  }
                  mandatory
                  disabled={isDisabled}
                />
              </div>
            )}
          />
          <Controller
            name="email"
            control={control}
            rules={{ required: "Email is required" }}
            render={({ field: { ref, ...field } }) => (
              <div className={errors?.email?.message && Styles.errorBorder}>
                <Input
                  {...field}
                  label="Email"
                  type="email"
                  labelPosition="left"
                  inputRef={ref}
                  mandatory
                  disabled={isDisabled}
                />
              </div>
            )}
          />
          <Controller
            name="dob"
            control={control}
            rules={{ required: "DOB is required" }}
            render={({ field: { ref, ...field } }) => (
              <div
                className={errors?.dob?.message && Styles.errorBorder}
                ref={ref}
              >
                <DynamicFormDatePicker
                  label="DOB"
                  type="single"
                  labelPosition="left"
                  setSelectedDateObj={(obj) => {
                    setValue("dob", getStructuredDate(obj.selectedDate), {
                      shouldDirty: true,
                    });
                  }}
                  defaultSelectedDateObj={field.value as IDatePickerSelection}
                  disabled={isDisabled}
                  disableMonthSelection={false}
                  disableYearSelection={false}
                  mandatory
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            name="ongoingEmis"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Input
                {...field}
                label="Ongoing Emis"
                labelPosition="left"
                disabled={isDisabled}
                inputRef={ref}
              />
            )}
          />
          <Controller
            name="employmentType"
            control={control}
            rules={{ required: "Employment type is required" }}
            render={({ field: { onChange, ref, ...field } }) => (
              <div
                className={
                  errors?.employmentType?.message && Styles.errorBorder
                }
                ref={ref}
              >
                <Select
                  {...field}
                  label="Employment Type"
                  labelPosition="left"
                  options={EMPLOYEMENT_TYPE}
                  setValue={onChange}
                  defaults={field?.value}
                  mandatory
                  disabled={isDisabled}
                />
              </div>
            )}
          />
          <Controller
            name="pincode"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Input
                {...field}
                label="Pin Code"
                labelPosition="left"
                disabled={isDisabled}
                inputRef={ref}
              />
            )}
          />
          <Controller
            name="state"
            control={control}
            render={({ field: { onChange, ref, ...field } }) => (
              <div ref={ref}>
                <Select
                  {...field}
                  label="State"
                  labelPosition="left"
                  options={STATES}
                  setValue={onChange}
                  defaults={field?.value}
                  disabled={isDisabled}
                />
              </div>
            )}
          />
          <Controller
            name="city"
            control={control}
            rules={{ required: "City is required" }}
            render={({ field: { onChange, ref, ...field } }) => (
              <div
                className={errors?.city?.message && Styles.errorBorder}
                ref={ref}
              >
                <Select
                  {...field}
                  label="City"
                  labelPosition="left"
                  options={CITIES}
                  setValue={onChange}
                  defaults={field?.value}
                  mandatory
                  disabled={isDisabled}
                />
              </div>
            )}
          />
        </div>
        <div className="mt-12">
          {Object.keys(errors).length > 0 && (
            <span className="flex justify-end m-1 text-xs text-error">
              Please fill all required fields
            </span>
          )}
          <div
            className={clsx("flex justify-between gap-10", {
              hidden: isDisabled,
            })}
          >
            <Button
              type="button"
              variant="outline"
              font="sm"
              classes="w-[235px] !text-lost !border-lost"
              onClick={(e) => {
                e.preventDefault();
                removeCoApplicant(coApplicant);
              }}
            >
              Remove Co-Applicant ({formIndex + 1})
            </Button>
            <div className="flex gap-2">
              {isLoading && (
                <div className="flex items-center text-gray">
                  <img src={Loading} alt="loading" className="w-7 h-7" />
                  <span>Saving changes...</span>
                </div>
              )}
              <Button
                type="submit"
                disabled={!isDirty}
                font="sm"
                classes="w-40"
              >
                Save
              </Button>
            </div>
          </div>
        </div>
        <hr className="border-t border-gray-border rounded-lg my-8" />
      </form>
    </div>
  );
};

export default CoApplicantForm;
