//* Package Import
import { ChangeEventHandler, useState } from "react";

//* Component Imports //
import Input from "@Core/Input";

//* Assets Imports */
import Upload from "@Assets/icons/upload.png";
import File from "@Assets/icons/file-icon.png";
import Error from "@Assets/icons/error.png";

export type InputFieldsType = {
  type: string;
  name: string;
  label: string;
  helperText?: string;
  errorEnabled?: boolean;
  className?: string;
  mandatory?: boolean;
  disabled?: boolean;
  accept?: string;
  multiple?: boolean;
  labelPosition?: "left" | "top";
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void; // Restrict to <input> only
  defaultValue?: string | number;
  required?: boolean;
};

const FileInput = (props: InputFieldsType) => {
  const [fileNames, setFileNames] = useState<string[]>([]);
  const {
    type,
    name,
    label,
    helperText,
    errorEnabled,
    mandatory,
    className,
    disabled = false,
    accept = "*",
    multiple = false,
    labelPosition,
    onChange,
    defaultValue,
    required = false,
  } = props;

  const handleFileChange: ChangeEventHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = e.target.files;
    if (files) {
      const names = Array.from(files).map((file) => file.name);
      setFileNames(names);
    }
    onChange?.(e);
  };

  return (
    <div>
      <Input
        id={name}
        labelPosition={labelPosition}
        className={className}
        label={label}
        type={type}
        name={name}
        onChange={handleFileChange}
        disabled={disabled}
        helperText={helperText}
        mandatory={mandatory}
        accept={accept}
        multiple={multiple}
        defaultValue={defaultValue}
        required={required}
      />

      <label
        htmlFor={name}
        className="flex items-center px-4 py-1 mt-2 mr-8 text-blue-500 border border-blue-500 rounded-lg cursor-pointer hover:bg-blue-50"
      >
        <img src={Upload} className="w-4 h-4 mr-2" alt="Upload Icon" />
        Upload file
      </label>
      {errorEnabled && (
        <div className="flex flex-row items-center gap-1 mt-2">
          <img src={Error} alt="errorIocn" />
          <span className="text-xs text-error ">
            Error uploading, Try again.
          </span>
        </div>
      )}

      <div className="mt-4">
        {fileNames?.map((fileName, index) => (
          <div key={`${fileName}-${index}`} className="mt-4">
            <div className="flex flex-row items-center">
              <img src={File} alt="file-icon" className="w-5 h-5" />
              <p className="text-sm underline">{fileName}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FileInput;
