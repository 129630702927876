//* Package Imports */
import { MutableRefObject, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";

//* Components Imports */
import TabList from "@Core/Tabs/TabList";
import TabPane from "@Core/Tabs/TabPane";
import Button from "@Core/Button";
import SkeletonCard from "@Core/SkeletonLoader/Card";
import TaskForm from "@Components/TaskSection/TaskForm";
import TasksCard from "@Components/TaskSection/TasksCard";

//* Hooks Imports */
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";
import useInfiniteScroll from "@Hooks/useInfiniteScroll";

//* Utils Imports */
import { TabPaneProps, ILeadTaskUpdatePayload } from "@Types/common";
import { lead } from "@Slice/LeadSlice";
import {
  getTasks,
  resetAllTasks,
  updateTaskFilters,
  updateTask,
  getTaskCategories,
} from "@Actions/lead";

//* Styles Imports */
import Styles from "@Components/TaskSection/TaskSection.module.scss";

const TaskSection = () => {
  const dispatch = useAppDispatch();
  const { leadId } = useParams();
  const { tasks: tasksData } = useAppSelector(lead);
  const canAccessTasks = useAppSelector(
    (state) => state.user.access?.detail.tasks,
  );

  const activeTab = tasksData.filters.isCompleted ? 1 : 0;
  const taskTabs = [
    {
      label: "Active Tasks",
      taskCount: tasksData?.activeTasksCount,
    },
    {
      label: "Completed Tasks",
      taskCount: tasksData?.completedTasksCount,
      customClass: Styles.green,
    },
  ];

  const [taskFormVisible, setTaskFormVisible] = useState<boolean>(false);

  const fetchTasks = async () => {
    if (!tasksData.hasMore) {
      return;
    }
    try {
      const payload = {
        leadId: Number(leadId),
      };
      await dispatch(getTasks(payload));
    } catch (err: unknown) {
      console.error(err);
    }
  };

  const handleTaskUpdate = async (id: number, data: ILeadTaskUpdatePayload) => {
    try {
      const payload = {
        leadId: Number(leadId),
        taskId: id,
        data,
      };
      await dispatch(updateTask(payload));
    } catch (err: unknown) {
      console.error(err);
    }
  };

  const handleTabChange = async (data: TabPaneProps) => {
    await dispatch(resetAllTasks());
    await dispatch(
      updateTaskFilters({ isCompleted: data?.label === "Completed Tasks" }),
    );
  };

  const [taskLoaderRef, tasksLoading] = useInfiniteScroll(
    fetchTasks,
    tasksData.hasMore,
    [tasksData.filters],
  );

  useEffect(() => {
    dispatch(getTaskCategories());
  }, []);

  return (
    <>
      {taskFormVisible && (
        <TaskForm handleCloseForm={() => setTaskFormVisible(false)} />
      )}

      {canAccessTasks?.add && !taskFormVisible && (
        <div className={Styles.sortTasks}>
          <Button
            type="button"
            variant="outline"
            onClick={() => setTaskFormVisible(true)}
          >
            + Create New Task
          </Button>
        </div>
      )}

      <TabList
        onTabChange={handleTabChange}
        tabId="tasksPanel"
        variant="bordered"
        activeTabIndex={activeTab}
        disabled={!!tasksLoading || tasksData.isWriteDisabled}
        tabContainerClasses={Styles.taskListContainer}
        tabTitleClasses={Styles.taskTabs}
      >
        {taskTabs.map((type) => (
          <TabPane
            key={type.label}
            label={type.label}
            subLabel={
              typeof type.taskCount === "number" && (
                <span className={clsx(Styles.taskCount, type.customClass)}>
                  {type.taskCount}
                </span>
              )
            }
          >
            <div className={Styles.tasksList}>
              {tasksData.list.map((task: any) => (
                <TasksCard
                  key={task.id}
                  task={task}
                  handleTaskUpdate={handleTaskUpdate}
                  updateTaskStatus={canAccessTasks?.change}
                />
              ))}
              <div
                className={Styles.loadingContainer}
                ref={taskLoaderRef as MutableRefObject<null>}
              >
                {tasksLoading && (
                  <SkeletonCard contentLines={3} listLength={3} />
                )}
                {!tasksData.hasMore &&
                  (tasksData.list?.length > 0 ? (
                    <div className={Styles.noMoreData}>No more data!</div>
                  ) : (
                    <div className={Styles.emptyData}>No data found!</div>
                  ))}
              </div>
            </div>
          </TabPane>
        ))}
      </TabList>
    </>
  );
};

export default TaskSection;
