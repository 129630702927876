//* Packages Imports */

//* Components Imports */
import Input from "@Core/Input";
import Select from "@Core/Select";

//* Data Imports */
import { countryCodeData, ICountryCode } from "@Constants/countryCode";

//* Interafce Defination */
interface IPhoneInputProps {
  label?: string;
  placeholder?: string;
  countryCodePlaceholder?: string;
  inputDefaultValue?: string;
  countryCodeDefaultValue?: ICountryCode | string | null;
  onCountryCodeSelect: (value: string | string[]) => void;
  onInputChange: (e: any) => void;
  options?: ICountryCode[];
  valueKey?: string;
  labelKey?: string;
  mandatory?: boolean;
  disabled?: boolean;
  helperText?: string | any;
}

const PhoneInput = ({
  label = "",
  placeholder = "Phone Number",
  countryCodePlaceholder = "+91",
  inputDefaultValue = "",
  countryCodeDefaultValue,
  onCountryCodeSelect,
  onInputChange,
  options = countryCodeData,
  valueKey = "dialCode",
  labelKey = "dialCode",
  mandatory = false,
  disabled = false,
  helperText,
}: IPhoneInputProps) => (
  <div>
    <div className="flex items-center gap-1">
      <label className="min-w-32 text-xs font-medium text-charcoal-gray m-0">
        <span className="text-error mr-[2px]">{mandatory && "*"}</span>
        {label}
      </label>
      <div className="w-full flex gap-2">
        <div className="min-w-[80px]">
          <Select
            placeholder={countryCodePlaceholder}
            setValue={onCountryCodeSelect}
            canSearch
            defaults={countryCodeDefaultValue}
            options={options}
            labelKey={labelKey}
            valueKey={valueKey}
            disabled={disabled}
          />
        </div>
        <div className="w-full">
          <Input
            type="number"
            placeholder={placeholder}
            defaultValue={inputDefaultValue}
            onChange={onInputChange}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
    {helperText && (
      <span className="flex mt-1 ml-[8.5rem] text-xs text-error">
        {helperText}
      </span>
    )}
  </div>
);

export default PhoneInput;
