//* Enums Imports */
import { StatusKeyEnum } from "@Enums/common";

//* Assets Imports */
import CrossIcon from "@Assets/icons/RedFilledCross.png";
import WithdrawnIcon from "@Assets/icons/RedFilledWithdrawn.png";

export const TIMELINE = [
  { slug: StatusKeyEnum.LEAD_FORWARDED, value: "Lead Forwarded" },
  { slug: StatusKeyEnum.CONTACTED, value: "Contacted" },
  { slug: StatusKeyEnum.AWAITING_DOCS, value: "Awaiting Docs" },
  { slug: StatusKeyEnum.READY_TO_LOGIN, value: "Ready to Login" },
  { slug: StatusKeyEnum.LOGGED_IN, value: "Logged In" },
  { slug: StatusKeyEnum.SANCTIONED, value: "Sanctioned" },
  { slug: StatusKeyEnum.PF_CONFIRMED, value: "PF Confirmed" },
  { slug: StatusKeyEnum.DISBURSED, value: "Disbursed" },
];

export const STATUS_TOAST_ICON: Record<string, string> = {
  WITHDRAWN: WithdrawnIcon,
  REJECTED: CrossIcon,
};

export const WITHDRAW_OPTIONS = [
  { label: "Accidental Assignment" },
  { label: "Student asked to withdrawn" },
  { label: "Other" },
];
