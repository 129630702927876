//* Packages */
import { Fragment } from "react/jsx-runtime";

//* Assets */
import UCLogo from "@Assets/images/uc-logo.png";
// import Notification from "@Assets/icons/notification.png";

//* Hooks */
import { useAppSelector } from "@Hooks/redux-hooks";

//* Components */
import Avatar from "@Core/Avatar";
import Popper from "@Components/utility/Popper";
import Search from "@Components/Header/Search";
import ProfilePopper from "@Components/Header/ProfilePopper";

//* Utils */
import { convertToTitleCase } from "@Utils/common";

export default function Header() {
  const navigationLinks = useAppSelector(
    (state) => state.navigation.navigationLinks,
  );
  const email = useAppSelector((state) => state.user.email);
  const role = useAppSelector((state) => state.user.role);

  return (
    <div className="w-full h-[60px] max-w-[1680px] shadow-md bg-white flex items-center px-5 pr-8 justify-between">
      <div className="flex items-center gap-4">
        <a href="/" className="cursor-pointer">
          <img src={UCLogo} alt="unicreds" width={132} />
        </a>

        {role === "lender" && (
          <div className="text-gray border-l border-gray-border text-sm pl-4">
            Lender Portal
          </div>
        )}
      </div>

      <Search />

      <div className="flex items-center gap-6">
        {navigationLinks.map((nav, index) => {
          return (
            <Fragment key={nav.id}>
              <a
                href={nav.link}
                target="_blank"
                className="cursor-pointer"
                rel="noreferrer"
              >
                <div className="flex items-center gap-2">
                  <img src={nav.icon} alt={nav.id} className="w-5 h-5" />
                  <span className="text-gray text-xs">{nav.label}</span>
                </div>
              </a>
              {index < navigationLinks.length - 1 && (
                <div className="h-4 w-[1px] bg-dark-gray" />
              )}
            </Fragment>
          );
        })}
      </div>

      <div className="flex items-center gap-4 cursor-pointer">
        {/* <div className="rounded-full w-9 h-9 shadow-md -mt-1 flex items-center justify-center relative">
          <img src={Notification} alt="notifications" className="w-4 h-4" />
          {notificationCount ? (
            <div className="rounded-full w-1.5 h-1.5 bg-error absolute top-2.5 right-2" />
          ) : null}
        </div> */}

        <Popper
          position="bottom-right"
          trigger={
            <Avatar
              name={email || "-"}
              subtext={convertToTitleCase(role) || "-"}
            />
          }
        >
          <ProfilePopper />
        </Popper>
      </div>
    </div>
  );
}
