//* Packages Imports */
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

//* Components Imports */
import SkeletonCard from "@Core/SkeletonLoader/Card";
import FormGenerator, { InputFieldsType } from "@Core/FormGenerator";

//* Service Imports */
import { lead } from "@Slice/LeadSlice";
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";
import { getAcademicDetails, setLeadAcademicDetails } from "@Actions/lead";

//* Data Imports */
import {
  ADMISSION_STATUS,
  DEGREES_LIST,
  EXAM_LIST,
} from "@Constants/leadDetailsData";

//* Assets Imports */
import AcademicDetailsIcon from "@Assets/icons/AcademicDetails.png";

//* Types Imports */
import { IAcademicDetails } from "@Types/leads";

const AcademicDetails = () => {
  const dispatch = useAppDispatch();
  const { isLoading, saveLoader, academicDetails } =
    useAppSelector(lead).leadDetails.academicDetailsObj;
  const { countries } = useAppSelector((state) => state.app);
  const { access } = useAppSelector((state) => state.user);

  const isDisabled = !access?.detail.academicDetails.change;
  const { leadId } = useParams();

  const formInputData: Array<InputFieldsType> = [
    {
      type: "select",
      name: "admissionStatus",
      label: "Admission Status",
      required: true,
      options: ADMISSION_STATUS,
      disabled: isDisabled,
    },
    {
      type: "text",
      name: "areaOfStudy",
      label: "Area of Study",
      required: true,
      disabled: isDisabled,
    },
    {
      type: "select",
      name: "country",
      label: "Country",
      options: countries,
      labelKey: "name",
      valueKey: "slug",
      disabled: isDisabled,
    },
    {
      type: "select",
      name: "degree",
      label: "Degree",
      required: true,
      options: DEGREES_LIST,
      disabled: isDisabled,
    },
    {
      type: "text",
      name: "university",
      label: "University",
      disabled: isDisabled,
    },
    {
      type: "date",
      name: "intake",
      label: "Intake",
      datePickerType: "single",
      disabled: isDisabled,
      disableMonthSelection: false,
      disableYearSelection: false,
    },
    {
      type: "select",
      name: "exams",
      label: "Exam Given",
      options: EXAM_LIST,
      disabled: isDisabled,
    },
    {
      type: "number",
      name: "overallScore",
      label: "Overall Score",
      disabled: isDisabled,
    },
    {
      type: "number",
      name: "noOfBacklogs",
      label: "No. of Backlogs",
      disabled: isDisabled,
    },
  ];

  const inputFieldsData = useMemo(() => {
    return formInputData;
  }, [countries]);

  const handleFormSubmit = (formData: IAcademicDetails) => {
    if (isNaN(Number(leadId))) return;

    dispatch(
      setLeadAcademicDetails({
        leadId: Number(leadId),
        payload: formData,
      }),
    );
  };

  useEffect(() => {
    if (leadId) dispatch(getAcademicDetails(Number(leadId)));
  }, [countries]);

  return (
    <div>
      <h3 className="flex gap-2 mb-7">
        <img src={AcademicDetailsIcon} alt="Academic Details" />
        Academics Details
      </h3>
      {isLoading ? (
        <SkeletonCard contentLines={9} gridCol={2} />
      ) : (
        <FormGenerator
          inputFieldsData={inputFieldsData}
          onSubmit={(formData) => handleFormSubmit(formData)}
          gridCol={2}
          defaultValues={academicDetails || {}}
          hideSubmitButton={isDisabled}
          disableSubmitBtn={saveLoader}
        />
      )}
    </div>
  );
};

export default AcademicDetails;
