export const LOGIN_DATA_MAPPER = {
  user_id: "userId",
  user_email: "userEmail",
  lead_details: "leadDetails",
  basic_details: "basicDetails",
  marketing_details: "marketingDetails",
  academic_details: "academicDetails",
  loan_details: "loanDetails",
  coapplicant_details: "coapplicantDetails",
  kyc_documents: "kycDocuments",
  academic_documents: "academicDocuments",
  financial_documents: "financialDocuments",
  bulk_document_upload: "bulkDocumentUpload",
  loan_application: "loanApplication",
  application_status: "applicationStatus",
  application_tracking: "applicationTracking",
  repeated_lead: "repeatedLead",
  conversion_data: "conversionData",
  disbursal_data: "disbursalData",
  coapplicant_docs: "coapplicantDocs",
};
