//* Packages  */
import clsx from "clsx";
import { InputHTMLAttributes } from "react";

//* Styles Imports */
import Style from "@Core/Button/Button.module.scss";

interface IButtonProps extends InputHTMLAttributes<HTMLButtonElement> {
  id?: string;
  variant?: "solid" | "outline" | "error" | "action";
  disabled?: boolean;
  font?: "xs" | "sm" | "md" | "lg";
  children?: React.ReactNode;
  href?: string;
  target?: "_blank" | "_self";
  onClick?: (e: any) => void;
  classes?: string;
  loading?: boolean;
}

export default function Button({
  id = "uc-btn",
  variant = "solid",
  disabled,
  font = "md",
  children,
  href,
  target = "_blank",
  onClick,
  classes = "",
  loading = false,
}: IButtonProps) {
  const fontClass = `text-${font}`;

  return (
    <button
      id={id}
      className={clsx(Style.btn, Style[variant], fontClass, classes, {
        [Style.loading]: loading,
      })}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {loading ? (
        "Loading.."
      ) : href ? (
        <a href={href} target={target}>
          {children}
        </a>
      ) : (
        children
      )}
    </button>
  );
}
