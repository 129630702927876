//* Packages Imports */
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";

//* Component Imports */
import Select from "@Core/Select";
import Pagination from "@Core/Pagination";
import QueryFilters from "@Components/Filter/QueryFilters";
import SkeletonList from "@Core/SkeletonLoader/List";
import MyTaskCard, { MyTaskHeader } from "@Components/MyTasks/MyTaskCard";

//* Utils Imports */
import { getMyTasks } from "@Actions/dashboard";
import { dashboard } from "@Slice/DashboardSlice";
import { userData } from "@Slice/AuthSlice";
import { PAGE_SIZE_OPTIONS } from "@Constants/common";
import { TASKS_FILTER_CONFIG, TASKS_FILTER_KEYS } from "@Constants/filtering";

//* Styles Imports */
import Styles from "@Components/MyTasks/MyTasks.module.scss";

const MyTasks = () => {
  const dispatch = useAppDispatch();
  const { myTasks } = useAppSelector(dashboard);
  const { role: authUserRole } = useAppSelector(userData);

  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [pageLimit, setPageLimit] = useState({ label: 10 });

  useEffect(() => {
    dispatch(
      getMyTasks({
        page_size: pageLimit.label,
        page,
        ...filters,
      }),
    );
  }, [page, pageLimit, filters]);

  const fetchNewData = (val: any) => {
    setFilters(val);
  };

  return (
    <div className={Styles.myTasksContainer}>
      <div className="bg-white px-5 py-2">
        <QueryFilters
          filterConfig={TASKS_FILTER_CONFIG}
          filterKeys={
            TASKS_FILTER_KEYS[authUserRole === "lender" ? "lender" : "agent"]
          }
          handleApplyFilters={fetchNewData}
          hasClearAll
        />
      </div>

      <div className={Styles.pageTitle}>
        Showing All Tasks
        <div className={Styles.pageRowsData}>
          Showing{" "}
          <span>
            <Select
              options={PAGE_SIZE_OPTIONS}
              valueKey="label"
              placeholder="10"
              defaults={pageLimit}
              size="xs"
              setValue={(data) => {
                setPageLimit(data);
                setPage(1);
              }}
              disabled={myTasks.loading || !myTasks.list?.length}
            />
          </span>
          rows
        </div>
      </div>
      <div className={Styles.myTasksTable}>
        <MyTaskHeader isLender={authUserRole === "lender"} />
        {myTasks.loading ? (
          <SkeletonList gridCols={4} listLength={Number(pageLimit.label)} />
        ) : myTasks.list?.length > 0 ? (
          myTasks.list.map((task) => (
            <MyTaskCard
              key={task.id}
              isLender={authUserRole === "lender"}
              task={task}
            />
          ))
        ) : (
          <div className={Styles.noData}>No data found</div>
        )}
      </div>
      {!myTasks.loading && Number(myTasks?.pages) > 1 && (
        <div className={Styles.paginationContainer}>
          <Pagination
            totalPages={myTasks.pages || 1}
            currentPage={page}
            setCurrentPage={(num: number) => {
              setPage(num);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default MyTasks;
