//* Components Imports */
import UserLogin from "@Components/LoginForm/UserLogin";
import OTPVerification from "@Components/LoginForm/OTPVerification";

//* Utils Imports */
import { userData } from "@Slice/AuthSlice";
import { useAppSelector } from "@Hooks/redux-hooks";

const LoginForm = () => {
  const user = useAppSelector(userData);

  const FORM_STEPS = {
    email: <UserLogin />,
    otp: <OTPVerification />,
  };

  //* Commented out the below code, will remove post testing
  // useEffect(() => {
  //   handleLogout();
  // }, []);

  return (
    <div className="w-full max-w-[501px] p-12 border border-gray-border rounded-lg bg-white">
      {FORM_STEPS[user.formStep]}
    </div>
  );
};

export default LoginForm;
