export const ADMISSION_STATUS = [
  {
    label: "Not Applied",
    value: "NOT APPLIED",
  },
  {
    label: "Applied",
    value: "APPLIED",
  },
  {
    label: "Conditional Offer Letter",
    value: "CONDITIONAL OFFER LETTER’",
  },
  {
    label: "Offer Letter",
    value: "UNCONDITIONAL OFFER LETTER",
  },
  {
    label: "Confirmed",
    value: "CONFIRMED",
  },
];

export const DEGREES_LIST = [
  {
    label: "Bachelors",
    value: "bachelors",
  },
  {
    label: "Masters",
    value: "masters",
  },
  {
    label: "Ph.D",
    value: "ph.d",
  },
  {
    label: "Diploma",
    value: "diploma",
  },
];

export const EXAM_LIST = [
  {
    label: "TOEFL",
    value: "TOEFL",
  },
  {
    label: "IELTS",
    value: "IELTS",
  },
  {
    label: "PTE",
    value: "PTE",
  },
  {
    label: "Duolingo",
    value: "Duolingo",
  },
  {
    label: "GRE",
    value: "GRE",
  },
  {
    label: "GMAT",
    value: "GMAT",
  },
  {
    label: "SAT",
    value: "SAT",
  },
];

export const LOAN_TYPE = [
  {
    label: "Secured",
    value: "SECURED",
  },
  {
    label: "Unsecured",
    value: "UNSECURED",
  },
];

export const EMPLOYEMENT_TYPE = [
  {
    label: "Salaried",
    value: "SALARIED",
  },
  {
    label: "Self Employed",
    value: "SELF_EMPLOYED",
  },
];
