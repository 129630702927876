import { IFilterConfig } from "@Types/common";
import { TIMELINE } from "@Constants/application";

export const DEFAULT_SORT_OPTIONS = [
  {
    label: "Latest",
    value: "-created_at",
  },
  { label: "Oldest", value: "created_at" },
];

export const TASKS_FILTER_CONFIG: Record<string, IFilterConfig> = {
  daterange: {
    type: "daterange",
    label: "Date Range",
    queryKey: "date_range",
  },
  leadStatus: {
    type: "list",
    label: "Lead Status",
    queryKey: "lead_status",
    variant: "multiSelect",
    options: [
      { label: "Created", value: "CREATED" },
      { label: "Contacted", value: "CONTACTED" },
    ],
  },
  taskStatus: {
    type: "list",
    label: "Task Status",
    variant: "singleSelect",
    queryKey: "task_status",
    options: [
      { label: "All", value: "all" },
      { label: "Active", value: "active" },
      { label: "Completed", value: "completed" },
      { label: "Overdue", value: "overdue" },
    ],
  },
  assignedTo: {
    type: "list",
    label: "Assigned to",
    variant: "singleSelect",
    queryKey: "assigned_to",
    options: [
      { label: "Assigned to me", value: "me" },
      { label: "Assigned to others", value: "others" },
    ],
  },
  applicationStatus: {
    type: "list",
    label: "Application Status",
    variant: "multiSelect",
    queryKey: "status",
    options: TIMELINE,
  },
};

export const TASKS_FILTER_KEYS = {
  agent: ["daterange", "leadStatus", "taskStatus", "assignedTo"],

  lender: ["daterange", "applicationStatus", "taskStatus"],
};
