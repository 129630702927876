//* Packages Imports */
import { useState } from "react";

//* Components Imports */
import Input from "@Core/Input";
import Button from "@Core/Button";

//* Service Imports */
import { sendOtpAction } from "@Actions/auth";
import { toastService } from "@Utils/toast";
import { getValueFromLS } from "@Utils/storage";
import { setLoading, userData } from "@Slice/AuthSlice";
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";

const EmailLoginForm = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(userData);

  const [email, setEmail] = useState<string>(
    getValueFromLS("user_email") || "",
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (!email) {
      toastService.notify("error", "Email is required");
      return;
    }

    dispatch(setLoading(true));
    e.preventDefault();

    const formData = new FormData();
    formData.append("email", email);
    dispatch(sendOtpAction(formData));
  };

  return (
    <form className="flex flex-col gap-9" onSubmit={handleSubmit}>
      <Input
        label="Email"
        type="email"
        placeholder="Enter your email address"
        required
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
      <Button variant="solid" type="submit" loading={user.loading}>
        Submit
      </Button>
    </form>
  );
};

export default EmailLoginForm;
