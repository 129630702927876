//* Packages */
import { RootState } from "@/store";
import { createSlice } from "@reduxjs/toolkit";

//* Utils */
import { ICountry, ITag, IPartner } from "@Types/common";

interface IAppState {
  appLoading: boolean;
  tags: ITag[];
  countries: ICountry[];
  partners: IPartner[];
}

const initialState: IAppState = {
  appLoading: false,
  tags: [],
  countries: [],
  partners: [],
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setAppLoading(state, action) {
      state.appLoading = action.payload;
    },
    setTags(state, action) {
      state.tags = action.payload;
    },
    setCountries(state, action) {
      state.countries = action.payload;
    },
    setPartners(state, action) {
      state.partners = action.payload;
    },
  },
});

export const { setAppLoading, setTags, setCountries, setPartners } =
  appSlice.actions;

export const appData = (state: RootState) => state.app;

export default appSlice.reducer;
