//* Package Imports */
import clsx from "clsx";

//* Utils Imports */
import { MONTHS_MAP } from "@Core/Datepicker/utils";

//* Assets Imports */
import ChevronDown from "@Assets/icons/ChevronDown.png";

//* Styles Imports */
import Styles from "@Core/Datepicker/MonthSelector.module.scss";

interface IMonthSelector {
  currSelectedMonth: number;
  currSelectedYear: number;
  handleYearChange: (val: number) => void;
  handleMonthChange: (val: number) => void;
  nextMonthAndYear: {
    month: number;
    year: number;
  };
  setShowYearTable: (state: {
    open: boolean;
    type: "year" | "month" | "";
  }) => void;
  showNeighbouringMonth?: boolean;
  disableMonthSelection?: boolean;
  disableYearSelection?: boolean;
}

const MonthSelector = ({
  currSelectedMonth,
  currSelectedYear,
  handleYearChange,
  handleMonthChange,
  nextMonthAndYear,
  setShowYearTable,
  showNeighbouringMonth = false,
  disableMonthSelection = false,
  disableYearSelection = false,
}: IMonthSelector) => {
  const currYear = new Date().getFullYear();

  function handleNextMonth() {
    if (currSelectedMonth === 11) {
      handleMonthChange(0);
      handleYearChange(currSelectedYear + 1);
    } else {
      handleMonthChange(currSelectedMonth + 1);
    }
  }
  function handlePrevMonth() {
    if (currSelectedMonth === 0) {
      handleMonthChange(11);
      handleYearChange(currSelectedYear - 1);
    } else {
      handleMonthChange(currSelectedMonth - 1);
    }
  }
  return (
    <div className={Styles.container}>
      <button
        type="button"
        disabled={currSelectedMonth === 0 && currSelectedYear === currYear}
        onClick={() => handlePrevMonth()}
        className={clsx(Styles.arrow, Styles.left)}
      >
        <img src={ChevronDown} alt="ChevronDown" />
      </button>
      <div className={Styles.heading}>
        <button
          type="button"
          disabled={disableMonthSelection}
          onClick={() => setShowYearTable({ open: true, type: "month" })}
          className={Styles.tab}
        >
          {MONTHS_MAP[currSelectedMonth]} &nbsp;
        </button>
        <button
          type="button"
          disabled={disableYearSelection}
          onClick={() => setShowYearTable({ open: true, type: "year" })}
          className={Styles.tab}
        >
          {currSelectedYear}
        </button>
      </div>
      {showNeighbouringMonth && (
        <div className={Styles.heading}>
          <button
            type="button"
            disabled={disableMonthSelection}
            onClick={() => setShowYearTable({ open: true, type: "month" })}
            className={Styles.tab}
          >
            {MONTHS_MAP[nextMonthAndYear?.month]} &nbsp;
          </button>
          <button
            type="button"
            disabled={disableYearSelection}
            onClick={() => setShowYearTable({ open: true, type: "year" })}
            className={Styles.tab}
          >
            {nextMonthAndYear?.year}
          </button>
        </div>
      )}
      <button
        type="button"
        onClick={() => handleNextMonth()}
        className={clsx(Styles.arrow, Styles.right)}
      >
        <img src={ChevronDown} alt="ChevronDown" />
      </button>
    </div>
  );
};

export default MonthSelector;
