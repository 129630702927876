import clsx from "clsx";
import CircleCross from "@Assets/icons/CircleCross.png";

interface IFilterTag {
  filterKey: string;
  label: string;
  value?: string | null;
  handleClear?: (filterKey: string) => void;
}

export default function FilterTag({
  filterKey,
  label,
  value = null,
  handleClear,
}: IFilterTag) {
  const containerClasses = clsx(
    "w-fit flex items-center text-xs text-gray border rounded min-h-6 py-1 px-2 cursor-pointer gap-1.5",
    value ? "border-pf-confirmed" : "border-gray-border",
  );

  const handleClearClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleClear?.(filterKey);
  };

  return (
    <div className={containerClasses}>
      {value && (
        <img
          onClick={handleClearClick}
          src={CircleCross}
          alt="clear"
          className="w-3 h-3"
        />
      )}
      <span>{label}</span>
      {value && (
        <span className="text-pf-confirmed font-medium border-l border-gray-border pl-2 ml-1">
          {value}
        </span>
      )}
    </div>
  );
}
