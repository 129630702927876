//* Packages Imports */
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

//* Components Imports */
import SkeletonCard from "@Core/SkeletonLoader/Card";
import FormGenerator, { InputFieldsType } from "@Core/FormGenerator";

//* Service Imports */
import { lead } from "@Slice/LeadSlice";
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";
import { getLeadBasicDetails, setLeadBasicDetails } from "@Actions/lead";

//* Data Imports */
import { CITIES, STATES } from "@Constants/mock";

//* Assets Imports */
import GraduatedIcon from "@Assets/icons/Graduated.png";

//* Types Imports */
import { IBasicDetails } from "@Types/leads";

const BasicDetails = () => {
  const dispatch = useAppDispatch();
  const { isLoading, saveLoader, basicDetails } =
    useAppSelector(lead).leadDetails.basicDetailsObj;
  const { access } = useAppSelector((state) => state.user);

  const isDisabled = !access?.detail.basicDetails.change;

  const { leadId } = useParams();

  const FormInputData: Array<InputFieldsType> = [
    {
      type: "text",
      name: "firstName",
      label: "First Name",
      required: true,
      disabled: isDisabled,
    },
    {
      type: "text",
      name: "lastName",
      label: "Last Name",
      required: true,
      disabled: isDisabled,
    },
    {
      type: "phone",
      name: "SecondaryMobileNo",
      label: "Secondary Mob. no.",
      required: true,
      disabled: isDisabled,
      placeholder: "Phone Number",
      countryCodeName: "alternateCountryCode",
    },
    {
      type: "date",
      name: "dob",
      label: "dob",
      required: true,
      datePickerType: "single",
      disabled: isDisabled,
      disableMonthSelection: false,
      disableYearSelection: false,
    },
    {
      type: "number",
      name: "pincode",
      label: "Pincode",
      disabled: isDisabled,
    },
    {
      type: "select",
      name: "state",
      label: "State",
      options: STATES,
      disabled: isDisabled,
    },
    {
      type: "select",
      name: "city",
      label: "City",
      required: true,
      options: CITIES,
      disabled: isDisabled,
    },
  ];

  const inputFieldsData = useMemo(() => {
    return FormInputData;
  }, []);

  const handleFormSubmit = (formData: IBasicDetails) => {
    if (isNaN(Number(leadId))) return;

    const updatedFormData = { ...formData };
    if (basicDetails?.id) updatedFormData.id = basicDetails.id;

    dispatch(
      setLeadBasicDetails({
        leadId: Number(leadId),
        payload: updatedFormData,
      }),
    );
  };

  useEffect(() => {
    if (leadId) dispatch(getLeadBasicDetails(Number(leadId)));
  }, []);

  return (
    <div>
      <h3 className="flex gap-2 mb-7">
        <img src={GraduatedIcon} alt="Graduated Icon" />
        Basic Details Form
      </h3>
      {isLoading ? (
        <SkeletonCard contentLines={9} gridCol={2} />
      ) : (
        <FormGenerator
          inputFieldsData={inputFieldsData}
          onSubmit={(formData) => handleFormSubmit(formData)}
          defaultValues={basicDetails || {}}
          gridCol={2}
          hideSubmitButton={isDisabled}
          disableSubmitBtn={saveLoader}
        />
      )}
    </div>
  );
};

export default BasicDetails;
