//* Packages Imports */
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

//* Components Imports */
import SkeletonCard from "@Core/SkeletonLoader/Card";
import FormGenerator, { InputFieldsType } from "@Core/FormGenerator";

//* Service Imports */
import { lead } from "@Slice/LeadSlice";
import { getMarketingDetails } from "@Actions/lead";
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";

//* Assets Imports */
import MarketingIcon from "@Assets/icons/Marketing.png";

const formInputData: Array<InputFieldsType> = [
  {
    type: "text",
    name: "utmSource",
    label: "UTM Source",
    disabled: true,
  },
  {
    type: "text",
    name: "referrer",
    label: "Referrer",
    disabled: true,
  },
  {
    type: "text",
    name: "utmCampaign",
    label: "UTM Campaign",
    disabled: true,
  },
  {
    type: "text",
    name: "clientIP",
    label: "Client IP",
    disabled: true,
  },
  {
    type: "text",
    name: "utmMedium",
    label: "UTM Medium",
    disabled: true,
  },
  {
    type: "text",
    name: "source",
    label: "Source",
    disabled: true,
  },
  {
    type: "text",
    name: "utmTerm",
    label: "UTM Term",
    disabled: true,
  },
  {
    type: "text",
    name: "element",
    label: "Element",
    disabled: true,
  },
  {
    type: "text",
    name: "utmContent",
    label: "UTM Content",
    disabled: true,
  },
];

const MarketingDetails = () => {
  const dispatch = useAppDispatch();
  const { isLoading, marketingDetails } =
    useAppSelector(lead).leadDetails.marketingDetailsObj;
  const { leadId } = useParams();

  const inputFieldsData = useMemo(() => {
    return formInputData;
  }, []);

  useEffect(() => {
    if (leadId) dispatch(getMarketingDetails(parseInt(leadId)));
  }, []);

  return (
    <div>
      <h3 className="flex gap-2 mb-7">
        <img src={MarketingIcon} alt="Marketing" />
        Marketing Details
      </h3>
      {isLoading ? (
        <SkeletonCard contentLines={9} gridCol={2} />
      ) : (
        <FormGenerator
          inputFieldsData={inputFieldsData}
          gridCol={2}
          defaultValues={marketingDetails || {}}
          hideSubmitButton
        />
      )}
    </div>
  );
};

export default MarketingDetails;
