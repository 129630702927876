//* Packages */
import { useEffect } from "react";
import { useParams } from "react-router-dom";

//* Components */
import TabList from "@Core/Tabs/TabList";
import TabPane from "@Core/Tabs/TabPane";
import TaskSection from "@Components/TaskSection";
import NotesSection from "@Components/NotesSection";
import Breadcrumbs, { Crumb } from "@Core/Breadcrumbs";
import LeadStatus from "@Components/pages/leads/lead-details/LeadStatus";
import LeadFormSection from "@Components/pages/leads/lead-details/LeadFormSection";
import LeadDetailsCard from "@Components/pages/leads/lead-details/LeadDetailsCard";
import LeadDetailsLoader from "@Components/pages/leads/lead-details/LeadDetailsCard/Loader";

//* Redux */
import {
  getLeadAssignees,
  getLeadDetails,
  updateLeadStatus,
} from "@Actions/lead";

//* Utils */
import { StatusKey } from "@Types/common";
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";

//* Mock */
// import { byPassRBACAccess } from "@/mock";
// import { setUserData } from "@Slice/AuthSlice";

export default function LeadDetails() {
  const { leadId } = useParams();
  const dispatch = useAppDispatch();

  const { access } = useAppSelector((state) => state.user);
  const { leadData, leadDataLoading } = useAppSelector((state) => state.lead);

  const handleStatusChange = async (val: StatusKey) => {
    dispatch(
      updateLeadStatus({
        id: leadData?.id as number,
        status: val,
      }),
    );
  };

  const CRUMBS: Crumb[] = [
    {
      id: 0,
      label: "Lead",
      link: "/",
    },
    {
      id: 1,
      label: `${leadData?.name || "-"} [${leadId}]`,
    },
  ];

  useEffect(() => {
    if (leadId) {
      dispatch(getLeadDetails(Number(leadId)));

      dispatch(getLeadAssignees({ leadId: Number(leadId) }));

      //* Uncomment this line to bypass RBAC access
      // dispatch(setUserData(byPassRBACAccess));
    }
  }, [leadId]);

  return (
    <div className="relative flex gap-4">
      <div className="left w-[72%]">
        {leadDataLoading ? (
          <LeadDetailsLoader />
        ) : (
          <>
            <Breadcrumbs crumbs={CRUMBS} />
            <div className="mt-4">
              <LeadStatus
                currentStatus={leadData?.leadinfo?.status as StatusKey}
                disabled={!access?.detail.leadDetails.change}
                onStatusClick={handleStatusChange}
              />
            </div>
            <div className="mt-4">
              <LeadDetailsCard />
            </div>
          </>
        )}
        <div className="mt-4">
          <LeadFormSection />
        </div>
      </div>
      {(access?.detail.notes.view || access?.detail.tasks.view) && (
        <div className="right bg-white border border-gray-border rounded-md w-[28%] max-h-[800px] flex flex-col">
          <TabList onTabChange={() => {}} tabId="tasksPanel" variant="solid">
            {access?.detail.notes.view && (
              <TabPane label="Notes" containerClasses="max-h-[750px]">
                <NotesSection />
              </TabPane>
            )}
            {access?.detail.tasks.view && (
              <TabPane label="Tasks" containerClasses="max-h-[750px]">
                <TaskSection />
              </TabPane>
            )}
          </TabList>
        </div>
      )}
    </div>
  );
}
