//* Packages Imports */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

//* Components Imports */
import Button from "@Core/Button";
import SkeletonCard from "@Core/SkeletonLoader/Card";
import CoApplicantForm from "@Components/pages/leads/lead-details/StudentDetailsSection/CoApplicantSection/CoApplicantForm";

//* Service Imports */
import { lead } from "@Slice/LeadSlice";
import { toastService } from "@Utils/toast";
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";
import { getCoApplicantDetails, removeCoApplicant } from "@Actions/lead";

//* Types Imports */
import { ICoApplicantDetails } from "@Types/leads";

const initialValues = {
  firstName: "",
  lastName: "",
  relation: "",
  phone: "",
  email: "",
  dob: "",
  countryCode: null,
  ongoingEmis: "",
  employmentType: null,
  pincode: "",
  state: null,
  city: null,
};
const CoApplicantSection = () => {
  const { leadId } = useParams();
  const dispatch = useAppDispatch();
  const { isLoading, coApplicantDetails } =
    useAppSelector(lead).leadDetails.coApplicantDetailsObj;
  const { access } = useAppSelector((state) => state.user);

  const [coApplicants, setCoApplicants] = useState<ICoApplicantDetails[]>([]);

  const handleRemoveCoApplicant = async (
    coApplicantData: ICoApplicantDetails,
  ) => {
    const updatedCoApplicants = coApplicants?.filter(
      (coApplicant: ICoApplicantDetails) =>
        coApplicant.id !== coApplicantData?.id,
    );
    if (!coApplicantData?.tempCoApplicant) {
      const payload = {
        leadId: parseInt(leadId || ""),
        coApplicantId: coApplicantData.id,
      };
      try {
        await dispatch(removeCoApplicant(payload));
      } catch (error) {
        console.error("error", error);
        toastService.notify("error", "Failed to remove co-applicant");
        return;
      }
    }
    setCoApplicants(updatedCoApplicants);
  };

  useEffect(() => {
    if (coApplicantDetails?.length) {
      setCoApplicants(coApplicantDetails);
    } else {
      setCoApplicants([
        {
          id: Math.floor(Math.random() * 1000),
          tempCoApplicant: true,
          ...initialValues,
        },
      ]);
    }
  }, [coApplicantDetails]);

  useEffect(() => {
    if (leadId) dispatch(getCoApplicantDetails(parseInt(leadId)));
  }, []);

  return (
    <>
      {isLoading ? (
        <SkeletonCard contentLines={9} gridCol={2} />
      ) : (
        <div>
          {coApplicants?.map(
            (coApplicant: ICoApplicantDetails, index: number) => (
              <div key={coApplicant?.id}>
                <CoApplicantForm
                  leadId={Number(leadId)}
                  coApplicant={coApplicant}
                  formIndex={index}
                  removeCoApplicant={handleRemoveCoApplicant}
                />
              </div>
            ),
          )}
          {access?.detail.coapplicantDetails.change && (
            <Button
              type="button"
              variant="outline"
              font="sm"
              classes="w-[235px]"
              disabled={coApplicants.length > 3}
              onClick={() =>
                setCoApplicants([
                  ...coApplicants,
                  {
                    id: Math.floor(Math.random() * 1000),
                    tempCoApplicant: true,
                    ...initialValues,
                  },
                ])
              }
            >
              + Add Co-applicant ({coApplicants.length + 1})
            </Button>
          )}
        </div>
      )}
    </>
  );
};

export default CoApplicantSection;
