//* Packages */
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

//* Components */
import Header from "@Components/Header";

//* Utils */
import { useAppDispatch } from "@Hooks/redux-hooks";

import { getCountries, getPartners } from "@Actions/app";

export default function DetailsLayout() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getPartners());
  }, []);

  return (
    <>
      <Header />
      <div className="p-4 min-h-screen bg-crm-bg">
        <Outlet />
      </div>
    </>
  );
}
