//* Packages */
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import QRCode from "react-qr-code";

//* Assets */
import Mail from "@Assets/icons/mail.png";
import Phone from "@Assets/icons/phone.png";
import Location from "@Assets/icons/location.png";
import University from "@Assets/icons/university.png";
import Rupee from "@Assets/icons/rupee.png";
import Whatsapp from "@Assets/icons/whatsapp.png";
import QR from "@Assets/icons/qr.png";
import GreenVerify from "@Assets/icons/green-verify.png";

//* Components */
import Popper from "@Components/utility/Popper";
import CopyToClipboard from "@Components/utility/CopyToClipboard";
import Tags from "@Components/pages/leads/lead-details/LeadDetailsCard/Tags";
import DataWidget from "@Components/pages/leads/lead-details/LeadDetailsCard/DataWidget";
import Agents from "@Components/pages/leads/lead-details/LeadDetailsCard/Agents";

//* Utils */
import { capitalizeText, formatCurrency } from "@Utils/common";
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";

//* Types & Enums */
import { ITag } from "@Types/common";
import { LEAD_TEAM_LIST } from "@Enums/common";
import { getMaskedInfo } from "@Actions/lead";

export default function LeadDetailsCard() {
  const dispatch = useAppDispatch();
  const { leadId } = useParams();
  const { leadData, unMasking } = useAppSelector((state) => state.lead);

  const widgets = useMemo(
    () => [
      {
        icon: Location,
        label: "Permanent City",
        subtext: `${capitalizeText(leadData?.permanentCity || "-")}`,
      },
      {
        icon: Rupee,
        label: "Loan Amount",
        subtext: `${formatCurrency(leadData?.loanAmount)}`,
      },
      {
        icon: Location,
        label: "Destination Country",
        subtext: `${leadData?.country || "-"}`,
      },
      {
        icon: University,
        label: "University",
        subtext: `${leadData?.university || "-"}`,
      },
    ],
    [leadData],
  );

  const agentsList = useMemo(
    () => ({
      [LEAD_TEAM_LIST.SALES_AGENT]: leadData?.leadinfo?.agent,
      [LEAD_TEAM_LIST.SANCTION_AGENT]: leadData?.leadinfo?.sanctionAgent,
      [LEAD_TEAM_LIST.ASSISTANT_MANAGER]: leadData?.leadinfo?.amAgent,
      [LEAD_TEAM_LIST.RG_AGENT]: leadData?.leadinfo?.rgAgent,
    }),
    [leadData],
  );

  const infoUnMaksed = unMasking.isEmailUnMasked && unMasking.isPhoneUnMasked;
  const unMaskFields = async (
    fieldNames: Array<"email" | "phone" | "alt_phone">,
  ) => {
    if (!leadId) return;

    const fieldsToUnmask = fieldNames.filter((fieldName) => {
      return (
        (fieldName === "email" && !unMasking.isEmailUnMasked) ||
        (fieldName === "phone" && !unMasking.isPhoneUnMasked)
      );
    });

    await Promise.all(
      fieldsToUnmask.map((fieldName) =>
        dispatch(getMaskedInfo({ leadId, fieldName })),
      ),
    );
  };

  return (
    <div className="bg-white border border-gray-border rounded-md py-4 px-[18px]">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <span className="font-medium text-black ">{leadData?.name} </span>
          <span className="text-lighter-gray"> | </span>

          <div className="flex items-center gap-1 text-xs">
            <span className="font-light text-dark-gray">Lead ID: </span>
            <span>{leadData?.id}</span>

            <CopyToClipboard content={leadData?.id || ""} />
          </div>

          <span className="text-lighter-gray"> | </span>

          <div className="text-xs font-light text-dark-gray">
            Created on:{" "}
            <span className="font-medium">{leadData?.createdAt}</span>{" "}
          </div>
        </div>

        <div className="flex items-center gap-1 text-xs text-gray">
          <span>Copy Details</span>
          <CopyToClipboard
            content={""}
            getFieldMaskedInfo={() => unMaskFields(["email", "phone"])}
            isUnMasked={infoUnMaksed}
          />
        </div>
      </div>

      <div className="flex items-start justify-between mt-5 ">
        <div className="grid w-3/5 grid-cols-2 gap-4">
          {widgets.map((widget) => (
            <DataWidget
              key={widget.label}
              icon={widget.icon}
              label={widget.label}
              subtext={widget.subtext}
            />
          ))}
        </div>

        <div className="flex flex-col gap-2 pl-6 font-light border-l cursor-pointer border-l-lighter-gray">
          <div className="flex items-center gap-2">
            <img src={Phone} alt="phone" className="w-5 h-5" />
            <span className="text-xs" onClick={() => unMaskFields(["phone"])}>
              {leadData?.phone}
            </span>

            {leadData?.verifiedPhone && (
              <img src={GreenVerify} alt="verified" className="w-4 h-4" />
            )}

            <CopyToClipboard
              content={leadData?.phone as string}
              isUnMasked={unMasking.isPhoneUnMasked}
              getFieldMaskedInfo={() => unMaskFields(["phone"])}
            />
            <Popper
              trigger={
                <img
                  src={QR}
                  alt="qr"
                  className="w-3 h-3"
                  onClick={() => unMaskFields(["phone"])}
                />
              }
            >
              {unMasking.isPhoneUnMasked && (
                <div className="p-4">
                  <QRCode
                    size={100}
                    value={`tel://${leadData?.phone}`}
                    viewBox="0 0 100 100"
                  />
                </div>
              )}
            </Popper>
            <img src={Whatsapp} alt="WA" className="w-4 h-4" />
          </div>

          <div
            className="flex items-center gap-2"
            onClick={() => unMaskFields(["email"])}
          >
            <img src={Mail} alt="mail" className="w-5 h-5" />
            <span className="text-xs">{leadData?.email}</span>

            {leadData?.verifiedEmail && (
              <img src={GreenVerify} alt="verified" className="w-4 h-4" />
            )}

            <CopyToClipboard
              content={leadData?.email as string}
              isUnMasked={unMasking.isEmailUnMasked}
              getFieldMaskedInfo={() => unMaskFields(["email"])}
            />
          </div>
        </div>
      </div>
      <div className="mt-8">
        <Agents data={agentsList} />
      </div>

      <div className="mt-6">
        <Tags leadTags={(leadData?.tags as ITag[]) || []} />
      </div>
    </div>
  );
}
