//* Packages */
import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

//* Slices */
import {
  addLeadTag,
  removeLeadTag,
  setLeadData,
  setLeadDataLoading,
  setTagsLoading,
  unshiftNotes,
  addNotes,
  setNotesUpcomingLink,
  resetNotes,
  setHasMoreNotes,
  setNoteCreateDisabled,
  setLeadAssignees,
  setMarketingDetails,
  setBasicDetails,
  setAcademicDetails,
  setLoanDetails,
  setCoApplicantDetails,
  updateCoApplicantDetails,
  removeCoApplicantDetails,
  setMarketingLoading,
  setBasicDetailsLoading,
  setAcademicDetailsLoading,
  setLoanDetailsLoading,
  setCoApplicantDetailsLoading,
  addTasks,
  setTasksUpcomingLink,
  setHasMoreTasks,
  setActiveTaskCount,
  setCompletedTaskCount,
  setTaskFilters,
  replaceTask,
  resetTaskList,
  setTaskWriteDisabled,
  setTaskCategories,
  unshiftTasks,
  setAssignedPartners,
  setSavingPartners,
  setAssignmentStatus,
  setDocumentsLoading,
  setDocuments,
  updateTeamData,
  setUnMaskingPhone,
  setUnMaskingEmail,
  setPhoneIsMasked,
  setEmailIsMasked,
  setAssignmentStatusRefresh,
} from "@Slice/LeadSlice";

//* Utils */
import {
  LEAD_DETAILS,
  TEAM_MEMBERS,
  UPDATE_TAGS,
  GET_NOTES,
  GET_LEAD_ASSIGNEES,
  LEAD_DETAILS_SECTION,
  GET_TASKS,
  GET_TASK_CATEGORIES,
  ASSIGNED_PARTNERS,
  APPLICATION_API,
  ASSIGNMENT_STATUS,
  DOCUMENTS,
  UPDATE_DOCUMENT,
  UNMASKING,
  LEAD_STATUS,
} from "@Constants/urls";
import {
  updateFieldFromOptions,
  transformLeadAssignees,
  transformLeadCreatedBy,
} from "@Utils/lead";
import API from "@Axios/main";
import { toastService } from "@Utils/toast";
import { getStructuredDate } from "@Utils/timeUtils";

//* Data Imports */
import { RootState } from "@/store";
import { CITIES, BOOLEAN_LIST, STATES } from "@Constants/mock";
import { countryCodeData, ICountryCode } from "@Constants/countryCode";
import {
  ADMISSION_STATUS,
  DEGREES_LIST,
  EMPLOYEMENT_TYPE,
  EXAM_LIST,
  LOAN_TYPE,
} from "@Constants/leadDetailsData";

import {
  LEAD_DETAILS_MAPPER,
  NOTE_MAPPER,
  LEAD_ASSIGNEE_MAPPER,
  MARKETING_DETAILS_MAPPER,
  ACADEMIC_DETAILS_MAPPER,
  BASIC_DETAILS_MAPPER,
  CO_APPLICANT_MAPPER,
  LOAN_DETAILS_MAPPER,
  TASK_MAPPER,
  DOCUMENTS_MAPPER,
} from "@Mappers/lead";

//* Types & Enums */
import {
  ILeadAssigneeUsers,
  INoteDetails,
  IPaginatedResponse,
  StatusKey,
  ISelect,
  ICountry,
  IPartner,
  ILeadTasks,
  ILeadTaskUpdatePayload,
  ILeadTaskCategory,
  IDatePickerSelection,
} from "@Types/common";
import {
  ILead,
  IAcademicDetails,
  IBasicDetails,
  ICoApplicantDetails,
  ILoanDetails,
  IMarketingDetails,
  LeadTeamType,
  Agent,
  MaskedInfo,
} from "@Types/leads";
import { TAG_ACTION } from "@Enums/common";
import { LoanDocs } from "@Types/documents";
import { setStatusList } from "@Slice/DashboardSlice";
interface IUpdateLeadStatus {
  id: number;
  status: StatusKey;
}

interface INotesCreatePayload {
  note: string;
  tagged_agents: number[] | null;
}

interface IStatusResponse {
  data: StatusKey[];
}

const _API = new API();

const apiCall = async (
  url: string,
  type: "get" | "post" | "patch",
  payload: any = null,
  mapper: any = {},
) => {
  let response;
  if (type === "get") response = await _API.get(url, mapper);
  if (type === "post") response = await _API.post(url, payload, mapper);
  if (type === "patch") response = await _API.patch(url, payload, mapper);
  return response;
};

export const getLeadDetails = createAsyncThunk(
  "lead/details",
  async (id: number, { dispatch }) => {
    dispatch(setLeadDataLoading(true));
    try {
      const [leadDetails, { data: leadStatuses }] = await Promise.all([
        _API.get<ILead>(`${LEAD_DETAILS}/${id}`, LEAD_DETAILS_MAPPER),
        _API.get<IStatusResponse>(LEAD_STATUS),
      ]);

      dispatch(setLeadData(leadDetails));

      if (leadStatuses) {
        dispatch(setStatusList(leadStatuses));
      }
    } catch (e) {
      console.error(e);
      toastService.notify("error", "Something went wrong, please try again!");
    } finally {
      dispatch(setLeadDataLoading(false));
    }
  },
);

export const updateLeadStatus = createAsyncThunk(
  "lead/updateStatus",
  async (payload: IUpdateLeadStatus, { dispatch }) => {
    dispatch(setLeadDataLoading(true));
    try {
      const res: ILead = await _API.patch(
        `${LEAD_DETAILS}/${payload.id}`,
        {
          status: payload.status,
        },
        LEAD_DETAILS_MAPPER,
      );

      dispatch(setLeadData(res));
      toastService.notify("success", "Status updated successfully!");
    } catch (err: unknown) {
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify("error", errorMessage || "Unexpected error occured!");
    } finally {
      dispatch(setLeadDataLoading(false));
    }
  },
);

export const updateTags = createAsyncThunk(
  "lead/updateTags",
  async (payload: any, { dispatch }) => {
    dispatch(setTagsLoading(true));
    try {
      await _API.post(UPDATE_TAGS(payload.leadId), {
        tag_code: payload.tag.code,
        action: payload.action,
      });
      if (payload.action === TAG_ACTION.ADD) dispatch(addLeadTag(payload.tag));
      else dispatch(removeLeadTag(payload.tag));
    } catch (e) {
      console.error(e);
      toastService.notify("error", "Something went wrong, please try again!");
    } finally {
      dispatch(setTagsLoading(false));
    }
  },
);

export const getAgentTeam = createAsyncThunk(
  "lead/agents",
  async (agentType: LeadTeamType, { dispatch }) => {
    dispatch(updateTeamData({ [agentType]: { loading: true } }));
    try {
      const res: Agent[] = await _API.get(
        TEAM_MEMBERS,
        {},
        {
          params: {
            agent_type: agentType,
          },
        },
      );
      if (res) {
        dispatch(
          updateTeamData({ [agentType]: { loading: false, list: res } }),
        );
      }
    } catch (e) {
      console.error(e);
    }
  },
);

export const createNewNote = createAsyncThunk(
  "lead/createNewNote",
  async (
    body: { data: INotesCreatePayload; leadId: number; callback?: () => void },
    { dispatch, getState },
  ) => {
    try {
      const { lead } = getState() as RootState;
      if (lead.notes.isCreateDisabled) return;
      dispatch(setNoteCreateDisabled(true));

      const response: INoteDetails = await _API.post(
        GET_NOTES(body.leadId),
        body.data,
        NOTE_MAPPER,
      );

      if (response?.id) {
        dispatch(unshiftNotes(response));
        toastService.notify("success", "Note created successfully!");
        body.callback?.();
      } else {
        throw new Error();
      }
    } catch (err: unknown) {
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify("error", errorMessage || "Unexpected error occured!");
    } finally {
      dispatch(setNoteCreateDisabled(false));
    }
  },
);

export const getNotes = createAsyncThunk(
  "lead/getNotes",
  async (
    body: { leadId?: number; filters?: Record<string, string> },
    { getState, dispatch },
  ) => {
    try {
      const { lead } = getState() as RootState;
      const res: IPaginatedResponse<INoteDetails[]> = await _API.get(
        lead.notes.upcomingLink || GET_NOTES(body.leadId || 1),
        NOTE_MAPPER,
        // Append filters only when upcoming link is not present (API will give next Link with filters)
        !lead.notes.upcomingLink
          ? {
              params: body.filters,
            }
          : undefined,
      );
      if (res?.results) {
        const noteList = transformLeadCreatedBy(res.results);
        dispatch(addNotes(noteList));
      }
      if (res?.next) dispatch(setNotesUpcomingLink(res.next));
      else dispatch(setHasMoreNotes(false));
    } catch (err: unknown) {
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify("error", errorMessage || "Error getting notes!");
      dispatch(setHasMoreNotes(false));
    }
  },
);

export const getLeadAssignees = createAsyncThunk(
  "lead/getLeadAssignees",
  async (body: { leadId?: number }, { getState, dispatch }) => {
    try {
      const { user: authUser } = getState() as RootState;
      const res: ILeadAssigneeUsers[] = await _API.get(
        GET_LEAD_ASSIGNEES,
        LEAD_ASSIGNEE_MAPPER,
        {
          params: {
            lead_id: body.leadId,
          },
        },
      );
      if (res?.length > 0) {
        const modifiedAssignees = transformLeadAssignees(res, authUser.id);
        dispatch(setLeadAssignees(modifiedAssignees));
      }
    } catch (err: unknown) {
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify("error", errorMessage || "Error getting assignees!");
    }
  },
);

export const resetAllNotes = createAsyncThunk(
  "lead/resetNotes",
  async (_, { dispatch }) => {
    dispatch(resetNotes());
  },
);

//* Lead Details Section Actions */

export const getMarketingDetails = createAsyncThunk(
  "leads/getMarketingDetails",
  async (leadId: number, { dispatch }) => {
    try {
      dispatch(setMarketingLoading(true));
      const response: IMarketingDetails[] = await _API.get(
        LEAD_DETAILS_SECTION(leadId, "marketing-details"),
        MARKETING_DETAILS_MAPPER,
      );
      dispatch(setMarketingDetails(response?.[0]));
    } catch (error: any) {
      console.error("Error", error);
      toastService.notify("error", "Failed to fetch lead marketing details");
    } finally {
      dispatch(setMarketingLoading(false));
    }
  },
);

export const getLeadBasicDetails = createAsyncThunk(
  "leads/getLeadBasicDetails",
  async (leadId: number, { dispatch }) => {
    try {
      dispatch(
        setBasicDetailsLoading({
          loading: true,
          type: "isLoading",
        }),
      );
      const response: IBasicDetails[] = await _API.get(
        LEAD_DETAILS_SECTION(leadId, "basic-details"),
        BASIC_DETAILS_MAPPER,
      );
      if (response?.[0] && Object.keys(response[0])?.length) {
        countryCodeData.forEach((country: ICountryCode) => {
          if (
            country.dialCode === (response[0].alternateCountryCode as string)
          ) {
            response[0].alternateCountryCode = country;
          }
        });
        response[0].dob = {
          selectedDate: response[0]?.dob,
          startDate: response[0]?.dob,
          endDate: null,
        } as IDatePickerSelection;

        updateFieldFromOptions(STATES, "state", response[0] as any);
        updateFieldFromOptions(CITIES, "city", response[0] as any);
        dispatch(setBasicDetails(response[0]));
      }
    } catch (error: any) {
      console.error("Error", error);
      toastService.notify("error", "Failed to fetch lead basic details");
    } finally {
      dispatch(
        setBasicDetailsLoading({
          loading: false,
          type: "isLoading",
        }),
      );
    }
  },
);

export const setLeadBasicDetails = createAsyncThunk(
  "leads/setLeadBasicDetails",
  async (body: { leadId: number; payload: IBasicDetails }, { dispatch }) => {
    try {
      dispatch(
        setBasicDetailsLoading({
          loading: true,
          type: "save",
        }),
      );

      let apiResponse: IBasicDetails | null = null;

      const payload = {
        first_name: body.payload?.firstName,
        last_name: body.payload?.lastName,
        alt_phone:
          (body.payload?.alternateCountryCode as ICountryCode)?.dialCode +
          body.payload?.SecondaryMobileNo,
        dob: getStructuredDate(
          (body.payload.dob as IDatePickerSelection).selectedDate,
        ),
        city: body.payload.city?.value,
        pincode: body.payload?.pincode,
        state: body.payload?.state?.value,
      };

      if (body.payload?.id) {
        apiResponse = (await apiCall(
          `${LEAD_DETAILS_SECTION(body.leadId, "basic-details")}/${body.payload.id}`,
          "patch",
          payload,
          BASIC_DETAILS_MAPPER,
        )) as IBasicDetails | null;
      } else {
        apiResponse = (await apiCall(
          `${LEAD_DETAILS_SECTION(body.leadId, "basic-details")}`,
          "post",
          { ...payload, lead: body.leadId },
          BASIC_DETAILS_MAPPER,
        )) as IBasicDetails | null;
      }

      const updatedResponse = {
        ...apiResponse,
        countryCode: body.payload.countryCode,
        alternateCountryCode: body.payload.alternateCountryCode,
        city: body.payload.city,
        state: body.payload.state,
      };

      dispatch(setBasicDetails(updatedResponse));
      toastService.notify("success", "Lead basic details updated successfully");
    } catch (error: any) {
      console.error("Error", error);
      toastService.notify(
        "error",
        error.response?.data?.[Object.keys(error.response?.data)[0]][0]
          ?.message || "Failed to set lead basic form details",
      );
      toastService.notify(
        "error",
        error.response?.data?.message || "Failed to set lead basic details",
      );
    } finally {
      dispatch(
        setBasicDetailsLoading({
          loading: false,
          type: "save",
        }),
      );
    }
  },
);

export const getAcademicDetails = createAsyncThunk(
  "leads/getAcademicDetails",
  async (leadId: number, { getState, dispatch }) => {
    try {
      const { app } = getState() as RootState;
      dispatch(
        setAcademicDetailsLoading({
          loading: true,
          type: "isLoading",
        }),
      );
      const response: IAcademicDetails[] = await _API.get(
        LEAD_DETAILS_SECTION(leadId, "academic-details"),
        ACADEMIC_DETAILS_MAPPER,
      );
      if (response?.length && Object.keys(response[0])?.length) {
        const updatedResponse = {
          ...response[0],
          admissionStatus: ADMISSION_STATUS.filter(
            (data) => data.value === response[0]?.admissionStatus,
          )[0],
          degree: DEGREES_LIST.filter(
            (data) => data.value === response[0]?.degree,
          )[0],
          country: app.countries.filter(
            (data) => data.id === response[0]?.country,
          )[0],
        };

        if (response[0]?.exams.length) {
          updatedResponse.exams = EXAM_LIST.filter(
            (data) => data.value === response[0]?.exams[0]?.name,
          )[0];
          updatedResponse.overallScore = response[0]?.exams[0]?.score;
        }

        if (response[0]?.intake) {
          updatedResponse.intake = {
            selectedDate: response[0]?.intake,
            startDate: response[0]?.intake,
            endDate: null,
          } as IDatePickerSelection;
        }

        dispatch(setAcademicDetails(updatedResponse));
      }
    } catch (error: any) {
      console.error("Error", error);
      toastService.notify("error", "Failed to fetch lead academic details");
    } finally {
      dispatch(
        setAcademicDetailsLoading({
          loading: false,
          type: "isLoading",
        }),
      );
    }
  },
);

export const setLeadAcademicDetails = createAsyncThunk(
  "leads/setAcademicDetails",
  async (body: { leadId: number; payload: IAcademicDetails }, { dispatch }) => {
    try {
      dispatch(
        setAcademicDetailsLoading({
          loading: true,
          type: "save",
        }),
      );

      let apiResponse: IAcademicDetails | null = null;

      const payload = {
        course: body.payload.areaOfStudy,
        admission_status: (body.payload.admissionStatus as ISelect)?.value,
        country: (body.payload.country as ICountry)?.id,
        degree: (body.payload?.degree as ISelect)?.value,
        university: body.payload?.university,
        no_of_backlogs: body.payload?.noOfBacklogs,
        exams: [
          {
            name: body.payload?.exams?.value,
            score: body.payload?.overallScore,
          },
        ],
        university_join_date: getStructuredDate(
          (body.payload?.intake as IDatePickerSelection).selectedDate,
        ),
      };

      if (body.payload?.id) {
        apiResponse = (await apiCall(
          `${LEAD_DETAILS_SECTION(body.leadId, "academic-details")}/${body.payload.id}`,
          "patch",
          payload,
          ACADEMIC_DETAILS_MAPPER,
        )) as IAcademicDetails | null;
      } else {
        apiResponse = (await apiCall(
          `${LEAD_DETAILS_SECTION(body.leadId, "academic-details")}`,
          "post",
          { ...payload, lead: body.leadId },
          ACADEMIC_DETAILS_MAPPER,
        )) as IAcademicDetails | null;
      }

      const updatedResponse = {
        ...apiResponse,
        admissionStatus: body.payload.admissionStatus,
        degree: body.payload.degree,
        exams: body.payload.exams,
        overallScore: body.payload.exams.score,
        country: body.payload.country,
      } as IAcademicDetails;

      dispatch(setAcademicDetails(updatedResponse));
      toastService.notify(
        "success",
        "Lead Academic details updated successfully",
      );
    } catch (error: any) {
      console.error("Error", error);
      toastService.notify(
        "error",
        error.response?.data?.[Object.keys(error.response?.data)[0]][0]
          ?.message || "Failed to set lead academic form details",
      );
      toastService.notify(
        "error",
        error.response?.data?.message || "Failed to set lead academic details",
      );
    } finally {
      dispatch(
        setAcademicDetailsLoading({
          loading: false,
          type: "save",
        }),
      );
    }
  },
);

export const getLoanDetails = createAsyncThunk(
  "leads/getLoanDetails",
  async (leadId: number, { getState, dispatch }) => {
    try {
      const { app } = getState() as RootState;
      dispatch(
        setLoanDetailsLoading({
          loading: true,
          type: "isLoading",
        }),
      );
      const response: any = await _API.get(
        LEAD_DETAILS_SECTION(leadId, "loan-details"),
        LOAN_DETAILS_MAPPER,
      );
      if (response?.length && Object.keys(response[0])?.length) {
        const updatedResponse = {
          ...response[0],
          loanType: LOAN_TYPE.filter(
            (data) => data.value === response[0].loanType,
          )[0],
          employed: BOOLEAN_LIST.filter(
            (data) => data.value === response[0].employed,
          )[0],
          salaried: BOOLEAN_LIST.filter(
            (data) => data.value === response[0].salaried,
          )[0],
        };

        if (response[0]?.alreadyAppliedTo.length) {
          updatedResponse.alreadyAppliedTo = [];
          app.partners.forEach((partner: IPartner) => {
            if (response[0]?.alreadyAppliedTo.includes(partner.name)) {
              updatedResponse.alreadyAppliedTo.push(partner);
            }
          });
        }

        dispatch(setLoanDetails(updatedResponse));
      }
    } catch (error: any) {
      console.error("Error", error);
      toastService.notify("error", "Failed to fetch lead loan details");
    } finally {
      dispatch(
        setLoanDetailsLoading({
          loading: false,
          type: "isLoading",
        }),
      );
    }
  },
);

export const setLeadLoanDetails = createAsyncThunk(
  "leads/setLeadLoanDetails",
  async (body: { leadId: number; payload: ILoanDetails }, { dispatch }) => {
    try {
      dispatch(
        setLoanDetailsLoading({
          loading: true,
          type: "save",
        }),
      );

      let apiResponse: ILoanDetails | null = null;

      const payload = {
        loan_amount: body.payload.loanAmount,
        loan_type: body.payload.loanType?.value,
        salaried: body.payload.salaried?.value,
        existing_emi: body.payload.existingEMI,
        employed: body.payload.employed?.value,
        already_applied_to: body.payload?.alreadyAppliedTo?.map(
          (option: IPartner) => option.name,
        ),
      };

      if (body.payload?.id) {
        apiResponse = (await apiCall(
          `${LEAD_DETAILS_SECTION(body.leadId, "loan-details")}/${body.payload.id}`,
          "patch",
          payload,
          LOAN_DETAILS_MAPPER,
        )) as ILoanDetails | null;
      } else {
        apiResponse = (await apiCall(
          `${LEAD_DETAILS_SECTION(body.leadId, "loan-details")}`,
          "post",
          { ...payload, lead: body.leadId },
          LOAN_DETAILS_MAPPER,
        )) as ILoanDetails | null;
      }

      const updatedResponse = {
        ...apiResponse,
        loanType: body.payload.loanType,
        employed: body.payload.employed,
        salaried: body.payload.salaried,
        alreadyAppliedTo: body.payload.alreadyAppliedTo,
      } as ILoanDetails;

      dispatch(setLoanDetails(updatedResponse));
      toastService.notify("success", "Lead Loan details updated successfully");
    } catch (error: any) {
      console.error("Error", error);
      toastService.notify(
        "error",
        error.response?.data?.[Object.keys(error.response?.data)[0]][0]
          ?.message || "Failed to set lead loan form details",
      );
      toastService.notify(
        "error",
        error.response?.data?.message || "Failed to set lead loan details",
      );
    } finally {
      dispatch(
        setLoanDetailsLoading({
          loading: false,
          type: "save",
        }),
      );
    }
  },
);

export const getCoApplicantDetails = createAsyncThunk(
  "leads/getCoApplicantDetails",
  async (leadId: number, { dispatch }) => {
    try {
      dispatch(
        setCoApplicantDetailsLoading({
          loading: true,
          type: "isLoading",
        }),
      );
      const response: ICoApplicantDetails[] = await _API.get(
        LEAD_DETAILS_SECTION(leadId, "coapplicant-details "),
        CO_APPLICANT_MAPPER,
      );

      const updatedResponse: ICoApplicantDetails[] = [];

      response?.forEach((coApplicant: ICoApplicantDetails, index: number) => {
        countryCodeData.forEach((country: ICountryCode) => {
          if (country.dialCode === coApplicant.countryCode) {
            coApplicant.countryCode = country;
          }
        });
        updateFieldFromOptions(
          countryCodeData,
          "countryCode",
          response[index] as any,
          "dialCode",
        );
        updateFieldFromOptions(STATES, "state", response[index] as any);
        updateFieldFromOptions(CITIES, "city", response[index] as any);
        updateFieldFromOptions(
          EMPLOYEMENT_TYPE,
          "employmentType",
          response[index] as any,
        );
        updatedResponse.push(coApplicant);
      });

      dispatch(setCoApplicantDetails(response));
    } catch (error: any) {
      console.error("Error", error);
      toastService.notify("error", "Failed to fetch lead co-applicant details");
    } finally {
      dispatch(
        setCoApplicantDetailsLoading({
          loading: false,
          type: "isLoading",
        }),
      );
    }
  },
);

export const setCoApplicantDetail = createAsyncThunk(
  "leads/setCoApplicantDetails",
  async (
    body: { leadId: number; payload: ICoApplicantDetails },
    { dispatch },
  ) => {
    try {
      dispatch(
        setCoApplicantDetailsLoading({
          loading: true,
          type: "save",
        }),
      );
      let apiResponse: ICoApplicantDetails | null = null;

      const payload = {
        first_name: body.payload?.firstName,
        last_name: body.payload?.lastName,
        email: body.payload?.email,
        phone:
          (body.payload.countryCode as ICountryCode)?.dialCode +
          body.payload?.phone,
        relation: body.payload?.relation,
        dob: body.payload?.dob,
        existing_emi: body.payload?.ongoingEmis,
        employment_type: body.payload?.employmentType?.value,
        pincode: body.payload?.pincode,
        state: body.payload?.state?.value,
        city: body.payload?.city?.value,
      };

      if (body.payload.id && !body.payload?.tempCoApplicant) {
        apiResponse = (await apiCall(
          `${LEAD_DETAILS_SECTION(body.leadId, "coapplicant-details")}/${body.payload.id}`,
          "patch",
          payload,
          CO_APPLICANT_MAPPER,
        )) as ICoApplicantDetails | null;
      } else {
        apiResponse = (await apiCall(
          `${LEAD_DETAILS_SECTION(body.leadId, "coapplicant-details")}`,
          "post",
          payload,
          CO_APPLICANT_MAPPER,
        )) as ICoApplicantDetails | null;
      }

      const updatedResponse = {
        ...apiResponse,
        countryCode: body.payload.countryCode,
        state: body.payload.state,
        city: body.payload.city,
        employmentType: body.payload.employmentType,
      } as ICoApplicantDetails;

      dispatch(updateCoApplicantDetails(updatedResponse));
    } catch (error: any) {
      console.error("Error", error);
      toastService.notify(
        "error",
        error.response?.data?.[Object.keys(error.response?.data)[0]][0]
          ?.message || "Failed to set co-Applicant details",
      );
      toastService.notify(
        "error",
        error.response?.data?.message ||
          "Failed to set lead co-applicant details",
      );
    } finally {
      dispatch(
        setCoApplicantDetailsLoading({
          loading: false,
          type: "save",
        }),
      );
    }
  },
);

export const removeCoApplicant = createAsyncThunk(
  "leads/removeCoApplicant",
  async (body: { leadId: number; coApplicantId: number }, { dispatch }) => {
    try {
      await _API.patch(
        `${LEAD_DETAILS_SECTION(body.leadId, "coapplicant-details")}/${body.coApplicantId}`,
        {
          is_deleted: true,
        },
      );
      dispatch(removeCoApplicantDetails(body.coApplicantId));
      toastService.notify(
        "success",
        "Lead co-applicant details removed successfully",
      );
    } catch (error: any) {
      console.error("Error", error);
      toastService.notify(
        "error",
        error.response?.data?.message ||
          "Failed to remove lead co-applicant details",
      );
    }
    // finally {
    //   dispatch(setCoApplicantDetailsLoading({
    //     loading: false,
    //     type: "isLoading",
    //   }));
    // }
  },
);

//* Task Section Actions */
export const getTasks = createAsyncThunk(
  "task/getTasks",
  async (body: { leadId?: number }, { getState, dispatch }) => {
    try {
      const { lead } = getState() as RootState;
      const filtersApplied = lead.tasks.filters;
      const taskParams = {
        is_complete: filtersApplied.isCompleted || false,
        lead_id: body.leadId,
      };

      const res: IPaginatedResponse<ILeadTasks[]> = await _API.get(
        lead.tasks.upcomingLink || GET_TASKS,
        TASK_MAPPER,
        // Append filters only when upcoming link is not present (API will give next Link with filters)
        !lead.tasks.upcomingLink
          ? {
              params: taskParams,
            }
          : undefined,
      );

      if (res?.results) {
        const taskList = transformLeadCreatedBy(res.results);
        dispatch(addTasks(taskList));
      }
      if (res?.next) dispatch(setTasksUpcomingLink(res.next));
      else dispatch(setHasMoreTasks(false));

      const countNotes = res?.count;

      if (filtersApplied?.isCompleted) {
        dispatch(setCompletedTaskCount(countNotes || 0));
      } else {
        dispatch(setActiveTaskCount(countNotes || 0));
      }
    } catch (err: unknown) {
      let errorMessage = "Error getting tasks!";
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify("error", errorMessage);
      dispatch(setHasMoreTasks(false));
    }
  },
);

export const updateTask = createAsyncThunk(
  "task/updateTask",
  async (
    body: { leadId: number; taskId: number; data: ILeadTaskUpdatePayload },
    { dispatch, getState },
  ) => {
    try {
      const { lead } = getState() as RootState;
      if (lead.tasks.isWriteDisabled) return;
      dispatch(setTaskWriteDisabled(true));

      const res: ILeadTasks = await _API.patch(
        `${GET_TASKS}/${body.taskId}`,
        { is_complete: body.data.isCompleted },
        TASK_MAPPER,
      );

      if (res?.id) {
        const transformedTasks = transformLeadCreatedBy([res]);
        dispatch(replaceTask(transformedTasks[0]));
        dispatch(setActiveTaskCount((lead.tasks.activeTasksCount || 1) - 1));
      }
    } catch (err: unknown) {
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify(
        "error",
        errorMessage || "Error occured while updating task!",
      );
    } finally {
      dispatch(setTaskWriteDisabled(false));
    }
  },
);

export const resetAllTasks = createAsyncThunk(
  "task/resetAllTasks",
  async (_, { dispatch }) => {
    dispatch(resetTaskList());
  },
);

export const updateTaskFilters = createAsyncThunk(
  "task/updateTaskFilters",
  async (filters: Record<string, boolean>, { dispatch }) => {
    dispatch(setTaskFilters(filters));
  },
);

export const getTaskCategories = createAsyncThunk(
  "lead/getTaskCategories",
  async (_, { dispatch }) => {
    try {
      const res: ILeadTaskCategory[] = await _API.get(GET_TASK_CATEGORIES);
      if (res?.length > 0) {
        dispatch(setTaskCategories(res));
      }
    } catch (err: unknown) {
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify("error", errorMessage || "Error getting assignees!");
    }
  },
);

interface ITaskCreatePayload {
  lead: number;
  summary?: string;
  remind_at: string;
  task_category: number;
  assignee: number;
}

export const createNewTask = createAsyncThunk(
  "lead/createNewTask",
  async (
    body: { data: ITaskCreatePayload; leadId: number; callback?: () => void },
    { dispatch, getState },
  ) => {
    try {
      const { lead } = getState() as RootState;
      if (lead.tasks.isWriteDisabled) return;
      dispatch(setTaskWriteDisabled(true));

      const response: ILeadTasks = await _API.post(
        GET_TASKS,
        body.data,
        TASK_MAPPER,
      );

      if (response?.id) {
        // Increase active tasks count
        dispatch(setActiveTaskCount((lead.tasks.activeTasksCount || 0) + 1));
        // Push to active tasks list
        if (!lead.tasks.filters.isCompleted) {
          dispatch(unshiftTasks(response));
        }
        toastService.notify("success", "Task created successfully!");
        body.callback?.();
      } else {
        throw new Error();
      }
    } catch (err: unknown) {
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify("error", errorMessage || "Unexpected error occured!");
    } finally {
      dispatch(setTaskWriteDisabled(false));
    }
  },
);

export const getAssignedPartners = createAsyncThunk(
  "lead/getAssignedPartners",
  async (leadId: number | string, { dispatch }) => {
    try {
      const res = await _API.get(ASSIGNED_PARTNERS(leadId));
      dispatch(setAssignedPartners(res));
    } catch (err: unknown) {
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify("error", errorMessage || "Unexpected error occured!");
    }
  },
);

export const assignPartners = createAsyncThunk(
  "lead/assignPartners",
  async (payload: { leadId: number; ids: number[] }, { dispatch }) => {
    try {
      dispatch(setSavingPartners(true));
      await _API.post(APPLICATION_API(payload.leadId), {
        partner_ids: payload.ids,
      });
      toastService.notify("success", "Partner(s) assigned successfully!");
    } catch (err: unknown) {
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify("error", errorMessage || "Unexpected error occured!");
    } finally {
      dispatch(setSavingPartners(false));
    }
  },
);

export const getAssignmentStatus = createAsyncThunk(
  "lead/getAssignmentStatus",
  async (leadId: number | string, { dispatch }) => {
    try {
      dispatch(setAssignmentStatusRefresh(true));
      const res: {
        leadAssignmentStatus: "READY" | "NOT_READY" | "PARTIALLY_READY";
      } = await _API.get(ASSIGNMENT_STATUS(leadId), {
        lender_assignment_status: "leadAssignmentStatus",
      });
      dispatch(setAssignmentStatus(res.leadAssignmentStatus));
    } catch (err: unknown) {
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify("error", errorMessage || "Unexpected error occured!");
    } finally {
      dispatch(setAssignmentStatusRefresh(false));
    }
  },
);

//* Loan Documents */
export const getLoanDocuments = createAsyncThunk(
  "lead/getLoanDocuments",
  async (uuid: string, { dispatch }) => {
    dispatch(setDocumentsLoading(true));
    try {
      const res: LoanDocs = await _API.get(
        `${DOCUMENTS}/${uuid}`,
        DOCUMENTS_MAPPER,
      );
      dispatch(setDocuments(res));
    } catch (err: unknown) {
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify(
        "error",
        errorMessage || "Error updating application!",
      );
    }
    dispatch(setDocumentsLoading(false));
  },
);

export const uploadDocument = createAsyncThunk(
  "lead/uploadDoc",
  async (payload: any, { dispatch }) => {
    const { uuid, formData } = payload;

    try {
      await _API.post(
        UPDATE_DOCUMENT,
        formData,
        {},
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      toastService.notify("success", "Successfully uploaded file!");
      dispatch(getLoanDocuments(uuid));
    } catch (err: unknown) {
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify("error", errorMessage || "Error uploading file!");
    }
  },
);

export const updateDocument = createAsyncThunk(
  "lead/updateDoc",
  async (payload: any) => {
    const { documentId, ...rest } = payload;

    try {
      await _API.patch(
        `${UPDATE_DOCUMENT}/${documentId}`,
        rest,
        {},
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      toastService.notify("success", "Successfully updated file!");
    } catch (err: unknown) {
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify("error", errorMessage || "Error updating file!");
    }
  },
);

export const getMaskedInfo = createAsyncThunk(
  "lead/getUnMaskedInfo",
  async (
    payload: { leadId: number | string; fieldName: string },
    { dispatch },
  ) => {
    const { leadId, fieldName } = payload;
    try {
      const response: MaskedInfo = await _API.get(UNMASKING(leadId, fieldName));
      if (response.phone) {
        dispatch(setPhoneIsMasked(true));
        dispatch(setUnMaskingPhone(response.phone));
      } else if (response.email) {
        dispatch(setEmailIsMasked(true));
        dispatch(setUnMaskingEmail(response.email));
      }
      return response;
    } catch (error: unknown) {
      console.error("Error:", error);
      let errorMessage;
      if (error instanceof AxiosError) {
        errorMessage = error.response?.data?.message || error.message;
      }
      toastService.notify(
        "error",
        errorMessage || "Failed to fetch information",
      );
    }
  },
);
