import clsx from "clsx";

interface IRadio {
  id?: string;
  label: string;
  value: string | number;
  checked?: boolean;
  disabled?: boolean;
  handleChange: (val: any) => void;
  optionJSX?: any;
  showOptionJSX?: boolean;
}

export default function Radio({
  id = "uc-radio",
  label,
  value,
  checked = false,
  disabled = false,
  handleChange,
  optionJSX,
  showOptionJSX = false,
}: IRadio) {
  {
    return (
      <div className="flex flex-col gap-2 w-full relative">
        <div className="flex items-center gap-2">
          <input
            id={id}
            type="radio"
            value={value}
            disabled={disabled}
            checked={checked}
            className="accent-disbursed cursor-pointer"
            onChange={() => handleChange(value)}
          />
          <label
            htmlFor={id}
            className={clsx("cursor-pointer", {
              "font-semibold": checked,
            })}
          >
            {label}
          </label>
        </div>
        {optionJSX && (showOptionJSX || checked) ? (
          <div className="mt-2 ml-10">{optionJSX}</div>
        ) : null}
      </div>
    );
  }
}
