//* Packages */
import clsx from "clsx";

//* Styles */
import Styles from "@Core/Toast/Toast.module.scss";

//* Components */
import Button from "@Core/Button";

//* Icons */
import SUCCESS from "@Assets/icons/green-tick.png";
import ERROR from "@Assets/icons/error-circle.png";
import WARNING from "@Assets/icons/warning-triangle.png";
import INFO from "@Assets/icons/info-circle.png";

export interface IToast {
  variant?: "success" | "warning" | "error" | "info";
  title: string;
  message?: string;
  actionLabel?: string;
  actionCallback?: () => void;
}

const ICON_VARIANT_MAP = {
  success: SUCCESS,
  warning: WARNING,
  info: INFO,
  error: ERROR,
};

export default function Toast({
  variant = "success",
  title,
  message,
  actionLabel = "Action",
  actionCallback,
}: IToast) {
  return (
    <div className={clsx(Styles.toast, Styles[variant])}>
      <div className="flex items-center gap-4">
        <img
          src={ICON_VARIANT_MAP[variant]}
          alt={`toast-${variant}`}
          width={20}
          height={20}
        />

        <div className={Styles.text}>
          <h3 className={Styles.title}>{title}</h3>
          <h3 className={Styles.message}>{message}</h3>
        </div>
      </div>

      <div className="flex items-center gap-4">
        {actionCallback ? (
          <Button variant="action" onClick={actionCallback}>
            {actionLabel}
          </Button>
        ) : null}

        <div className={Styles.close}>&times;</div>
      </div>
    </div>
  );
}
