//* Assets */
import Browser from "@Assets/icons/browser.png";
import AddUser from "@Assets/icons/add-user.png";
import Growth from "@Assets/icons/growth.png";

//* Types */
import { Link } from "@Types/common";

export const NAVIGATION_LINKS: Link[] = [
  {
    id: "add-lead",
    label: "Add Lead",
    icon: AddUser,
    link: "/add-lead",
  },
  {
    id: "dashboard",
    label: "Dashboard",
    icon: Growth,
    link: "/dashboard",
  },
  {
    id: "website",
    label: "Website",
    icon: Browser,
    link: "https://www.unicreds.com",
  },
];
