//* Packages */
import { useState } from "react";

//* Assets */
import SearchIcon from "@Assets/icons/search.png";

//* Components */
import Popper from "@Components/utility/Popper";
import LeadCardSearch, { ILead } from "@Components/LeadCardSearch";

//* Utils */
import API from "@Axios/main";
import debounce from "@Utils/debounce";
import { SEARCH } from "@Constants/urls";
import { SEARCH_MAPPER } from "@Mappers/app";
import { toastService } from "@Utils/toast";

export default function Search() {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<ILead[]>([]);

  const _API = new API("v1");

  const handleSearch = debounce(async (q: string) => {
    setQuery(q);
    if (q === "") {
      setSuggestions([]);
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const res: ILead[] = await _API.get(SEARCH, SEARCH_MAPPER, {
        params: {
          search: q,
        },
      });
      setSuggestions(res);
    } catch (e) {
      console.error(e);
      toastService.notify("error", "Something went wrong, please try again!");
    } finally {
      setLoading(false);
    }
  });

  return (
    <div>
      <Popper
        position="bottom-left"
        trigger={
          <div className="bg-tag-light-gray w-[320px] h-[42px] flex items-center gap-2 px-4 rounded-lg">
            <img src={SearchIcon} alt="search" className="w-4 h-4" />
            <input
              placeholder="Search by lead id, name, email or phone"
              className="text-gray text-xs focus:outline-none bg-tag-light-gray w-full"
              onChange={(e) => handleSearch(e.target.value)}
            ></input>
          </div>
        }
      >
        <div className="flex flex-col w-[690px] h-[400px] overflow-auto rounded-lg shadow-lg">
          {loading ? (
            <span className="text-xs text-gray p-4">
              Fetching your leads...
            </span>
          ) : query.length ? (
            <div>
              <div className="flex items-center text-tag-gray text-xs p-4 pb-0">
                <span className="w-[400px]">Lead Info</span>
                <span className="w-1/2">Sales Agent</span>
                <span className="w-1/3">Lead Status</span>
              </div>
              {suggestions?.length ? (
                suggestions.map((suggestion) => (
                  <LeadCardSearch key={suggestion.leadID} data={suggestion} />
                ))
              ) : (
                <span className="text-xs text-gray p-4">
                  No data found, try again with a different query.
                </span>
              )}
            </div>
          ) : (
            <span className="text-xs text-gray p-4">Type to search...</span>
          )}
        </div>
      </Popper>
    </div>
  );
}
