/* eslint-disable no-useless-catch */
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from "axios";
import { MapBackendToFrontend } from "@Axios/mapping-framework";
import { getCookiesValue } from "@Utils/storage";
import { handleLogout } from "@Utils/auth";

class API {
  private api: AxiosInstance;

  public token = getCookiesValue("auth_token");

  constructor(version: "v1" | "v2" = "v2") {
    const headerConfig: AxiosRequestHeaders = {
      "Content-Type": "application/json",
    } as AxiosRequestHeaders;

    if (this.token) {
      headerConfig.Authorization = `Bearer ${this.token}`;
    }

    this.api = axios.create({
      baseURL: `${import.meta.env.VITE_UC_APIURL}${version}/`,
      headers: headerConfig,
    });

    this.api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          handleLogout();
        }
        return Promise.reject(error);
      },
    );
  }

  public async get<T>(
    endpoint: string,
    frontendModelConfig: any = {},
    config: AxiosRequestConfig = {},
  ): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.api.get(endpoint, config);
      let updatedResponse = response.data;
      if (Object.keys(frontendModelConfig).length) {
        const modelMapper = new MapBackendToFrontend(frontendModelConfig);
        updatedResponse = modelMapper.mapBackendToFrontend(response.data);
      }
      return updatedResponse;
    } catch (error: any) {
      throw error;
    }
  }

  public async post<T>(
    endpoint: string,
    data: any,
    frontendModelConfig: any = {},
    config: AxiosRequestConfig = {},
  ): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.api.post(
        endpoint,
        data,
        config,
      );
      let updatedResponse = response.data;
      if (Object.keys(frontendModelConfig).length) {
        const modelMapper = new MapBackendToFrontend(frontendModelConfig);
        updatedResponse = modelMapper.mapBackendToFrontend(response.data);
      }
      return updatedResponse;
    } catch (error: any) {
      throw error;
    }
  }

  public async patch<T>(
    endpoint: string,
    data: any,
    frontendModelConfig: any = {},
    config: AxiosRequestConfig = {},
  ): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.api.patch(
        endpoint,
        data,
        config,
      );
      let updatedResponse = response.data;
      if (Object.keys(frontendModelConfig).length) {
        const modelMapper = new MapBackendToFrontend(frontendModelConfig);
        updatedResponse = modelMapper.mapBackendToFrontend(response.data);
      }
      return updatedResponse;
    } catch (error: any) {
      throw error;
    }
  }

  public async delete<T>(
    endpoint: string,
    config: AxiosRequestConfig = {},
  ): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.api.delete(
        endpoint,
        config,
      );
      return response.data;
    } catch (error: any) {
      throw error;
    }
  }
}

export default API;
