import { STATUS_COLOR } from "@Core/StatusBadge/constants";
import { StatusKey } from "@Types/common";

interface IStatusBadge {
  status: StatusKey;
  fontSize?: "text-sm" | "text-md" | "text-xs" | "text-lg";
}

export default function StatusBadge({
  status,
  fontSize = "text-sm",
}: IStatusBadge) {
  const statusOb = STATUS_COLOR[status];
  const bg = STATUS_COLOR[status]?.color;
  const text = STATUS_COLOR[status]?.text;

  return (
    <div
      className={`px-3 py-1 rounded w-fit  ${fontSize} font-medium ${bg} ${text || "text-white"}`}
    >
      {statusOb?.label || status}
    </div>
  );
}
