//* Package Imports */
import { AxiosResponse } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

//* URL Imports */
import {
  VERIFY_EMAIL_LOGIN,
  SEND_EMAIL_OTP,
  VERIFY_EMAIL_OTP,
} from "@Constants/urls";

//* Service Imports */
import {
  setAuthorised,
  setFormStep,
  setLoading,
  setUserData,
} from "@Slice/AuthSlice";

//* Utils Imports */
import API from "@Axios/main";
import { toastService } from "@Utils/toast";
import { setValueToLS } from "@Utils/storage";
import { handleLogout, handleSuccessfulLogin } from "@Utils/auth";

//* Types Imports */
import { IAuthAction, IAuthResponse } from "@Types/auth";

//* Utils */
import { LOGIN_DATA_MAPPER } from "@Mappers/auth";
import { FORM_STEP } from "@Enums/common";

const loginAPI = new API();
const loginAPIV1 = new API("v1");

export const verifyGoogleLogin = createAsyncThunk(
  "auth/verifyGoogleLogin",
  async (
    body: {
      data: IAuthAction;
      redirectUrl: string | null;
    },
    { dispatch },
  ) => {
    try {
      const response: AxiosResponse<IAuthResponse> = await loginAPIV1.post(
        VERIFY_EMAIL_LOGIN,
        body.data,
        LOGIN_DATA_MAPPER,
      );
      dispatch(setAuthorised(true));
      dispatch(setUserData(response));
      await handleSuccessfulLogin({
        ...response,
        redirectUrl: body.redirectUrl,
      });
    } catch (error: any) {
      console.error("error", error);
      toastService.notify(
        "error",
        error?.response?.data?.details || "Something went wrong",
      );
      handleLogout();
    }
  },
);

export const sendOtpAction = createAsyncThunk(
  "auth/sendOtpAction",
  async (body: FormData, { dispatch }) => {
    try {
      await loginAPI.post(
        SEND_EMAIL_OTP,
        body,
        {},
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      toastService.notify("success", "OTP sent successfully!");
      setValueToLS("user_email", body.get("email"));
      dispatch(setFormStep(FORM_STEP.OTP));
    } catch (error: any) {
      console.error("error", error);
      toastService.notify(
        "error",
        error?.response?.data?.details ||
          "Unable to send OTP, please try again",
      );
    } finally {
      dispatch(setLoading(false));
    }
  },
);

export const verifyEmailOTP = createAsyncThunk(
  "auth/verifyEmailOTP",
  async (body: FormData, { dispatch }) => {
    try {
      const response: AxiosResponse<IAuthResponse> = await loginAPI.post(
        VERIFY_EMAIL_OTP,
        body,
        LOGIN_DATA_MAPPER,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      dispatch(setAuthorised(true));
      dispatch(setFormStep(FORM_STEP.EMAIL));
      dispatch(setUserData(response));
      await handleSuccessfulLogin(response);
    } catch (error: any) {
      console.error("error", error);
      toastService.notify(
        "error",
        error?.response?.data?.details || "Something went wrong",
      );
    }
  },
);
