//* Service imports */
import {
  removeCookiesValue,
  removeValueFromLS,
  setCookiesValue,
} from "@Utils/storage";
import { persistor } from "@Store/index";

export const padZeroAtStart = (num: number) => {
  if (num > 10) return num;

  return num.toString().padStart(2, "0");
};

export const handleSuccessfulLogin = async (resData: any) => {
  const redirectUrl = null;

  //* Commenting redirect URL for now needs time to debug :( */
  // if (resData?.redirectUrl) {
  //   redirectUrl = resData.redirectUrl;
  // } else {
  //   const params = new URLSearchParams(window.location.search);
  //   redirectUrl = await params.get("redirect");
  // }

  removeValueFromLS("user_email");
  removeCookiesValue("google_csrf_token");
  await setCookiesValue("auth_token", resData.token, 604800);
  if (resData.role) {
    await setCookiesValue("user_role", resData.role, 604800);
  }
  await setCookiesValue("user_id", resData.userId, 604800);
  await setCookiesValue("user_email", resData.userEmail, 604800);

  if (redirectUrl) {
    window.location.href = `${import.meta.env.VITE_CRM_URL}${redirectUrl}`;
  } else {
    window.location.href = "/";
  }
};

export const handleLogout = async () => {
  await persistor.purge();
  await removeCookiesValue("auth_token");
  await removeCookiesValue("user_role");
  await removeCookiesValue("user_id");
  await removeCookiesValue("user_email");
  await removeCookiesValue("google_csrf_token");
  if (window.location.pathname !== "/login") {
    window.location.href = "/login";
  }
};
