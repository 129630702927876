//* Package Imports */
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";

//* Utils Imports */
import {
  DAYS,
  MONTHS_MAP,
  formatDate,
  getMonthStartingDay,
  getNoOfDaysBetweenDates,
  leapYear,
} from "@Core/Datepicker/utils";

//* Styles Imports */
import Styles from "@Core/Datepicker/DaysTable.module.scss";

interface IDaysTable {
  currSelectedYear: number;
  currSelectedMonth: number;
  nextMonthAndYear: {
    month: number;
    year: number;
  };
  showNeighbouringMonth?: boolean;
  type: "single" | "range";
  disablePreviousDates: boolean;
  setDateRange: (
    dates:
      | {
          startDate: Date | null;
          endDate: Date | null;
          noOfDaysSelected?: number;
        }
      | { selectedDate: Date | null },
  ) => void;
  defaultSelectedDateObj?: {
    startDate: Date | string | null;
    endDate: Date | string | null;
  };
  showClearDates: boolean;
}

const DaysTable = ({
  currSelectedYear,
  currSelectedMonth,
  nextMonthAndYear,
  showNeighbouringMonth = false,
  type,
  disablePreviousDates,
  setDateRange,
  defaultSelectedDateObj,
  showClearDates,
}: IDaysTable) => {
  const [selectedDates, setSelectedDates] = useState<{
    startDate: null | string | Date;
    endDate: null | string | Date;
  }>({
    startDate: null,
    endDate: null,
  });
  const isDatePickerDirty = useRef(false);

  const MONTH_DAYS_MAP = useRef<Record<number, number>>({
    0: 31,
    1: 28,
    2: 31,
    3: 30,
    4: 31,
    5: 30,
    6: 31,
    7: 31,
    8: 30,
    9: 31,
    10: 30,
    11: 31,
  });

  function isWithinRange(
    startDate: Date | string,
    date: string,
    endDate: Date | string,
  ) {
    const _startDate = new Date(startDate);
    const _date = new Date(date);
    const _endDate = new Date(endDate);
    return _date > _startDate && _date < _endDate;
  }

  function handleDateSelect(date: string | number | Date) {
    try {
      if (type === "single") {
        if (selectedDates.startDate === date) {
          setSelectedDates({ startDate: null, endDate: null });
        } else {
          setSelectedDates({
            startDate: date as string | null,
            endDate: null,
          });
        }
      } else {
        const currSelectedDate = new Date(date);
        const startDate = selectedDates.startDate
          ? new Date(selectedDates.startDate)
          : null;
        const endDate = selectedDates.endDate
          ? new Date(selectedDates.endDate)
          : null;
        if (startDate && endDate) {
          if (+currSelectedDate === +startDate) {
            setSelectedDates({
              startDate: selectedDates.endDate,
              endDate: null,
            });
          } else if (+currSelectedDate === +endDate) {
            setSelectedDates({ ...selectedDates, endDate: null });
          } else {
            const newDateKey =
              currSelectedDate < startDate ? "startDate" : "endDate";
            setSelectedDates({
              ...selectedDates,
              [newDateKey]: formatDate(currSelectedDate),
            });
          }
        } else {
          if (!startDate) {
            setSelectedDates({
              ...selectedDates,
              startDate: formatDate(currSelectedDate),
            });
          } else if (+startDate === +currSelectedDate && !+(endDate as Date)) {
            setSelectedDates({
              ...selectedDates,
              endDate: formatDate(currSelectedDate),
            });
          } else if (
            +currSelectedDate === +startDate ||
            (endDate !== null && +currSelectedDate === +endDate)
          ) {
            setSelectedDates({ ...selectedDates, endDate: null });
          } else {
            if (+currSelectedDate < +startDate) {
              setSelectedDates({
                startDate: formatDate(currSelectedDate),
                endDate: formatDate(startDate),
              });
            } else {
              setSelectedDates({
                ...selectedDates,
                endDate: formatDate(currSelectedDate),
              });
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      isDatePickerDirty.current = true;
    }
  }

  function clearDateSelection() {
    setSelectedDates({ startDate: null, endDate: null });
    emitData("clear");
  }

  const generateCalendar = (noOfDays: number, month: number, year: number) => {
    const rows = [];
    let cells = [];
    const _noOfDays = leapYear(year) && month === 1 ? 29 : noOfDays;
    const startDayOfMonth = getMonthStartingDay(year, month);

    for (let i = 0; i < startDayOfMonth; i++) {
      cells.push(<td className={Styles.dateCell} key={`empty-${i}`}></td>);
    }

    for (let i = 1; i <= _noOfDays; i++) {
      cells.push(
        <td
          key={i}
          data-date={`${i}-${MONTHS_MAP[month]}-${year}`}
          data-disabled={
            disablePreviousDates &&
            new Date(`${i}-${MONTHS_MAP[month]}-${year}`).getTime() <
              new Date().setHours(0, 0, 0, 0)
          }
          onClick={(e) => {
            const targetElement = e.currentTarget;
            if (targetElement?.dataset?.disabled === "true") return;
            handleDateSelect(targetElement?.dataset?.date as string);
          }}
          className={clsx(Styles.dateCell, {
            [Styles.disabled]:
              disablePreviousDates &&
              new Date(`${i}-${MONTHS_MAP[month]}-${year}`).getTime() <
                new Date().setHours(0, 0, 0, 0),
            [Styles.selectedDate]:
              selectedDates.startDate === `${i}-${MONTHS_MAP[month]}-${year}` ||
              selectedDates.endDate === `${i}-${MONTHS_MAP[month]}-${year}`,
            [Styles.highlight]:
              selectedDates.startDate &&
              selectedDates.endDate &&
              isWithinRange(
                selectedDates.startDate,
                `${i}-${MONTHS_MAP[month]}-${year}`,
                selectedDates.endDate,
              ),
          })}
        >
          {i}
        </td>,
      );
      if ((i + startDayOfMonth) % 7 === 0 || i === _noOfDays) {
        rows.push(
          <tr key={`row-${i}`} className={Styles.row}>
            {cells}
          </tr>,
        );
        cells = [];
      }
    }

    return rows;
  };

  function emitData(
    emitType = "fill",
    dateOb: {
      startDate: string | null | Date;
      endDate: string | null | Date;
    } = {
      startDate: "",
      endDate: "",
    },
  ) {
    if (emitType === "clear") {
      if (type === "range") {
        setDateRange({ startDate: null, endDate: null });
        return;
      }
      if (type === "single") {
        setDateRange({ selectedDate: null });
        return;
      }
    }

    if (type === "single") {
      setDateRange({
        selectedDate: dateOb?.startDate ? new Date(dateOb?.startDate) : null,
      });
    } else {
      setDateRange({
        startDate: dateOb?.startDate ? new Date(dateOb?.startDate) : null,
        endDate: dateOb?.endDate ? new Date(dateOb?.endDate) : null,
        noOfDaysSelected: getNoOfDaysBetweenDates(
          dateOb?.startDate as string,
          dateOb?.endDate as string,
        ),
      });
    }
  }

  useEffect(() => {
    if (!selectedDates?.startDate || !isDatePickerDirty?.current) {
      return;
    }
    emitData("fill", selectedDates);
  }, [selectedDates]);

  useEffect(() => {
    if (defaultSelectedDateObj?.startDate) {
      setSelectedDates({
        startDate: formatDate(defaultSelectedDateObj.startDate),
        endDate: defaultSelectedDateObj.endDate
          ? formatDate(defaultSelectedDateObj.endDate)
          : null,
      });
    }
  }, [defaultSelectedDateObj]);

  return (
    <>
      <div className={Styles.container}>
        <table className={Styles.calendarTable}>
          <thead className={Styles.header}>
            <tr className={Styles.headerRow}>
              {DAYS.map((day) => (
                <th className={Styles.headerData} key={day}>
                  {day}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={Styles.tableBody}>
            {generateCalendar(
              MONTH_DAYS_MAP.current[currSelectedMonth],
              currSelectedMonth,
              currSelectedYear,
            )}
          </tbody>
        </table>
        {showNeighbouringMonth && (
          <table className={Styles.calendarTable}>
            <thead className={Styles.header}>
              <tr className={Styles.headerRow}>
                {DAYS.map((day) => (
                  <th className={Styles.headerData} key={day}>
                    {day}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className={Styles.tableBody}>
              {generateCalendar(
                MONTH_DAYS_MAP.current[nextMonthAndYear?.month],
                nextMonthAndYear?.month,
                nextMonthAndYear?.year,
              )}
            </tbody>
          </table>
        )}
      </div>
      {showClearDates && (
        <div className={Styles.clearDate} onClick={clearDateSelection}>
          Clear Dates
        </div>
      )}
    </>
  );
};

export default DaysTable;
