export const STATUS_TEXT_MAP = {
  READY: {
    title: "Ready to be Assigned",
    class: "text-success",
    subtext:
      "This lead has all the required mandatory documents for lender assignment.",
  },
  NOT_READY: {
    title: "Not Ready for Assignment",
    class: "text-error",
    subtext:
      "Lead is missing one or more mandatory documents or student details.",
  },
  PARTIALLY_READY: {
    title: "Partial Documents",
    class: "text-warning",
    subtext: "Mandatory documents uploaded, but some are still missing.",
  },
};
export const DOC_NAME: Record<string, string> = {
  aadharFront: "Aaadhar Card (Front)",
  aadharBack: "Aaadhar Card (Back)",
  motherAadharFront: "Mother Aadhar Card (Front)",
  PAN: "PAN Card",
  passport: "Passport",
  passportPhoto: "Passport Size Photo",
  motherPAN: "Mother PAN Card",
  fatherPAN: "Father PAN Card",
  tenthMarksheet: "10th Marksheet",
  twelfthMarksheet: "12th Marksheet",
  graduationMarksheet: "Graduation Marksheet",
  postGraduationMarksheet: "Post Graduation Marksheet",
};

export const PAGE_SIZE_OPTIONS = [
  { label: "10" },
  { label: "20" },
  { label: "50" },
];
