import { StatusKey } from "@Types/common";
import StatusBadge from "@Core/StatusBadge";

export interface ILead {
  leadName: string;
  leadEmail: string;
  agentName: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  leadID: number;
}

interface ILeadCardSearch {
  data: ILead;
}

export default function LeadCardSearch({ data }: ILeadCardSearch) {
  return (
    <a
      href={`${import.meta.env.VITE_CRM_URL}/leads/${data.leadID}`}
      target="_blank"
      rel="noreferrer"
    >
      <div className="flex items-center justify-between w-full p-4 pb-2 border-b border-gray-border last:border-none">
        <div className="flex flex-col gap-0.5 w-[400px]">
          <span className="text-black text-xs font-medium ">
            {data.leadName}{" "}
            <span className="text-link text-x hover:underline">
              (#{data.leadID})
            </span>
          </span>
          <div className="flex items-center">
            <span className="text-gray text-xs font-light">
              {data.leadEmail}
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-0.5 w-1/2">
          <span className="text-black text-xs font-medium">
            {data.agentName}
          </span>
          <span className="text-gray text-xs font-light">{data.createdAt}</span>
        </div>
        <div className="flex flex-col gap-0.5 w-1/3">
          <StatusBadge status={data.status as StatusKey} />
        </div>
      </div>
    </a>
  );
}
