//* Packages */
import { createSlice } from "@reduxjs/toolkit";

//* Utils */
import { NAVIGATION_LINKS } from "@Constants/navigation";

const initialState = {
  navigationLinks: NAVIGATION_LINKS,
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState: initialState,
  reducers: {},
});

export default navigationSlice.reducer;
