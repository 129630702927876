export const SEARCH_MAPPER = {
  lead_name: "leadName",
  lead_email: "leadEmail",
  agent_name: "agentName",
  created_at: "createdAt",
  updated_at: "updatedAt",
  lead_id: "leadID",
};

export const TAGS_MAPPER = {
  created_at: "createdAt",
  updated_at: "updatedAt",
  assigned_at: "assignedAt",
};
