export default function LeadDetailsLoader() {
  return (
    <div className="flex flex-col">
      <div className="h-4 w-[100px] rounded bg-light-gray"></div>

      <div className="mt-4">
        <div className="h-[90px] w-full rounded bg-light-gray"></div>
      </div>

      <div className="mt-4">
        <div className="h-[317px] w-full rounded bg-light-gray"></div>
      </div>
    </div>
  );
}
