//* Package Imports */
import { Route, Routes } from "react-router-dom";

//* Guarding */
import Public from "@Router/Public";
import Protected from "@Router/Protected";

//* Layouts */
import DetailsLayout from "@Layouts/details";
import DashboardLayout from "@Layouts/dashboard";

//* Pages */
import Login from "@Pages/login";
import Dashboard from "@Pages/dashboard";
import NotFound from "@Pages/404";
import LeadDetails from "@Pages/leads/lead-details";
import Authorize from "@Pages/authorize/authorize";

//* Service Imports */
import { userData } from "@Slice/AuthSlice";
import { useAppSelector } from "@Hooks/redux-hooks";

export default function AppRoutes() {
  const user = useAppSelector(userData);

  const isAuth = user.authorised;

  return (
    <Routes>
      <Route element={<Protected isAuthenticated={isAuth} />}>
        <Route element={<DetailsLayout />}>
          <Route path="/leads/:leadId" element={<LeadDetails />} />
        </Route>
        <Route element={<DashboardLayout />}>
          <Route element={<Dashboard />} path="/" />
        </Route>
      </Route>

      <Route element={<Public isAuthenticated={isAuth} />}>
        <Route element={<Login />} path="/login"></Route>
        <Route element={<Authorize />} path="/authorize"></Route>
      </Route>

      <Route element={<NotFound />} path="/*"></Route>
    </Routes>
  );
}
