//* Packages Imports */
import clsx from "clsx";

//* Components Imports */
import StatusBadge from "@Core/StatusBadge";

//* Service Imports */
import { dashboard } from "@Slice/DashboardSlice";
import { STATUS_COLOR } from "@Core/StatusBadge/constants";
import { useAppSelector } from "@Hooks/redux-hooks";

//* Types Imports */
import { StatusKey } from "@Types/common";

//* Interface Declaration */
interface ILeadStatus {
  currentStatus: StatusKey;
  disabled?: boolean;
  onStatusClick?: (status: StatusKey) => void;
}

const LeadStatus = ({
  currentStatus,
  disabled,
  onStatusClick,
}: ILeadStatus) => {
  const { leads: allLeads } = useAppSelector(dashboard);
  const statusList = allLeads.statusList;

  return (
    <div className="grid grid-cols-6 gap-2">
      {statusList?.length > 0 &&
        statusList.map((status) => (
          <div
            className="flex items-center justify-center border-r border-r-gray-border h-8"
            key={status}
          >
            {status === currentStatus ? (
              <StatusBadge status={currentStatus} />
            ) : (
              <div
                className={clsx("text-black text-sm px-4 py-3 cursor-pointer", {
                  "!cursor-not-allowed": disabled,
                })}
                onClick={
                  !disabled && onStatusClick
                    ? () => onStatusClick(status)
                    : undefined
                }
              >
                {STATUS_COLOR[status]?.label}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default LeadStatus;
