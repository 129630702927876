//* Component Imports */
import Select from "@Core/Select";

//* Utils Imports */
import { getAgentTeam } from "@Actions/lead";
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";
import { lead } from "@Slice/LeadSlice";

//* Types & Enums Imports */
import { Agent, LeadTeamType } from "@Types/leads";
import { LEAD_TEAM_LIST, LENDER_TEAM_LIST } from "@Enums/common";

interface IAgentsProps {
  data: {
    [K in LeadTeamType]?: Agent;
  };
}

const AGENT_MEMBER_LIST = [
  {
    title: "Sales Agent",
    slug: LEAD_TEAM_LIST.SALES_AGENT,
  },
  {
    title: "Sanction Agent",
    slug: LEAD_TEAM_LIST.SANCTION_AGENT,
  },
  {
    title: "Assistant Manager",
    slug: LEAD_TEAM_LIST.ASSISTANT_MANAGER,
  },
  {
    title: "RG Agent",
    slug: LEAD_TEAM_LIST.RG_AGENT,
  },
];

const LENDER_MEMBER_LIST = [
  {
    title: "Lender Executive",
    slug: LENDER_TEAM_LIST.LENDER_EXECUTIVE,
  },
  {
    title: "Lender Manager",
    slug: LENDER_TEAM_LIST.LENDER_MANAGER,
  },
  {
    title: "Lender Regional Head",
    slug: LENDER_TEAM_LIST.LENDER_REGIONAL_HEAD,
  },
];

export default function Agents({ data }: IAgentsProps) {
  const dispatch = useAppDispatch();
  const { teamData, leadData } = useAppSelector(lead);
  const canUpdateAgent = useAppSelector(
    (state) => state.user.access?.detail.leadDetails.change,
  );

  const fetchTeamByAgent = (agentType: LeadTeamType) => () => {
    if (!teamData?.[agentType]) dispatch(getAgentTeam(agentType));
  };

  return (
    <div className="flex items-center gap-4">
      {(leadData?.lenderData ? LENDER_MEMBER_LIST : AGENT_MEMBER_LIST).map(
        (teamMember) => (
          <div
            key={teamMember.slug}
            className="flex flex-col gap-[6px] w-[160px]"
          >
            <span className="text-placeholder-gray text-xxs">
              {teamMember.title}
            </span>
            <Select
              labelKey="name"
              valueKey="id"
              size="xs"
              fetchOptions={fetchTeamByAgent(teamMember.slug)}
              options={teamData?.[teamMember.slug]?.list || []}
              disabled={!canUpdateAgent}
              optionsLoading={teamData?.[teamMember.slug]?.loading}
              defaults={data[teamMember.slug] || []}
            />
          </div>
        ),
      )}
    </div>
  );
}
