export const STATES = [
  {
    label: "Maharashtra",
    value: "maharashtra",
  },
  {
    label: "Goa",
    value: "goa",
  },
  {
    label: "Gujarat",
    value: "gujarat",
  },
];

export const CITIES = [
  {
    label: "Mumbai",
    value: "mumbai",
  },
  {
    label: "Pune",
    value: "pune",
  },
  {
    label: "Thane",
    value: "thane",
  },
];

export const BOOLEAN_LIST = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];
